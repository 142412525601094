// @flow
import Mixpanel from 'mixpanel-browser';
import type TrackerInterface from './TrackerInterface';
import { getCurrentDateTime } from '../dateUtils';

class MixpanelTracker implements TrackerInterface {
  constructor() {
    Mixpanel.init(process.env.REACT_APP_MP_ID);

    const buildVersion = process.env.TRAVIS_BUILD_NUMBER;
    if (buildVersion) {
      Mixpanel.register({ buildVersion });
    }
  }

  setUserInfo = (userId, userEmail) =>
    new Promise((resolve) => {
      Mixpanel.identify(userId);
      Mixpanel.register({ email: userEmail });
      Mixpanel.people.set({ $email: userEmail, $last_login: getCurrentDateTime(), $name: userEmail }, resolve);
    });

  setBusiness = (businessId, businessName, businessLegalName, isNed, IsTan, tanIds, tanLegalName) => {
    Mixpanel.register({ businessId, businessName, businessLegalName, isNed, IsTan, tanIds, tanLegalName });
  };

  trackPageChange = (pageName) => {
    this.pageUrl = pageName;
    return this.trackEvent('Page Load');
  };

  trackEvent = (event, properties) =>
    new Promise((resolve) => {
      const props = { ...properties };

      if (this.pageUrl) {
        props.pageUrl = this.pageUrl;
      }

      Mixpanel.track(event, props, resolve);
    });

  logout = () => {
    Mixpanel.reset();
  };
}

export default MixpanelTracker;

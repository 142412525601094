import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import ls from 'local-storage';

import commonEN from './locales/en/common.json';
import commonDE from './locales/de/common.json';
import controllingEN from './locales/en/controlling.json';
import controllingDE from './locales/de/controlling.json';
import documentsEN from './locales/en/documents.json';
import documentsDE from './locales/de/documents.json';
import bookkeepingEN from './locales/en/bookkeeping.json';
import bookkeepingDE from './locales/de/bookkeeping.json';
import overviewEN from './locales/en/overview.json';
import overviewDE from './locales/de/overview.json';
import clientProfileEN from './locales/en/clientProfile.json';
import clientProfileDE from './locales/de/clientProfile.json';
import todoEN from './locales/en/todo.json';
import todoDE from './locales/de/todo.json';
import taxAdvisorEN from './locales/en/taxAdvisorOverview.json';
import taxAdvisorDE from './locales/de/taxAdvisorOverview.json';
import taxAdvisorBookkeepingEN from './locales/en/taxAdvisorBookkeeping.json';
import taxAdvisorBookkeepingDE from './locales/de/taxAdvisorBookkeeping.json';
import commonTodoEN from './locales/en/commonTodo.json';
import commonTodoDE from './locales/de/commonTodo.json';
import taxAdvisorTodoEN from './locales/en/taxAdvisorTodo.json';
import taxAdvisorTodoDE from './locales/de/taxAdvisorTodo.json';
import masterDataEN from './locales/en/masterData.json';
import masterDataDE from './locales/de/masterData.json';
import bookingLogicEN from './locales/en/bookingLogic.json';
import bookingLogicDE from './locales/de/bookingLogic.json';
import storiesTranslationsEN from './locales/en/storiesTranslations.json';
import storiesTranslationsDE from './locales/de/storiesTranslations.json';
import employeesAndPayrollEN from './locales/en/employeesAndPayroll.json';
import employeesAndPayrollDE from './locales/de/employeesAndPayroll.json';
import customersAndSuppliersEN from './locales/en/customersAndSuppliers.json';
import customersAndSuppliersDE from './locales/de/customersAndSuppliers.json';
import paymentAccountsEN from './locales/en/paymentAccounts.json';
import paymentAccountsDE from './locales/de/paymentAccounts.json';
import monthEndClosingEn from './locales/en/monthEndClosing.json';
import monthEndClosingDe from './locales/de/monthEndClosing.json';
import mixpanel from './utils/mixpanel';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        common: commonEN,
        controlling: controllingEN,
        documents: documentsEN,
        bookkeeping: bookkeepingEN,
        overview: overviewEN,
        clientProfile: clientProfileEN,
        todo: todoEN,
        taxAdvisorOverview: taxAdvisorEN,
        taxAdvisorBookkeeping: taxAdvisorBookkeepingEN,
        commonTodo: commonTodoEN,
        taxAdvisorTodo: taxAdvisorTodoEN,
        masterData: masterDataEN,
        bookingLogic: bookingLogicEN,
        storiesTranslations: storiesTranslationsEN,
        employeesAndPayroll: employeesAndPayrollEN,
        customersAndSuppliers: customersAndSuppliersEN,
        paymentAccounts: paymentAccountsEN,
        monthEndClosing: monthEndClosingEn,
      },
      de: {
        common: commonDE,
        controlling: controllingDE,
        documents: documentsDE,
        bookkeeping: bookkeepingDE,
        overview: overviewDE,
        clientProfile: clientProfileDE,
        todo: todoDE,
        taxAdvisorOverview: taxAdvisorDE,
        taxAdvisorBookkeeping: taxAdvisorBookkeepingDE,
        commonTodo: commonTodoDE,
        taxAdvisorTodo: taxAdvisorTodoDE,
        masterData: masterDataDE,
        bookingLogic: bookingLogicDE,
        storiesTranslations: storiesTranslationsDE,
        employeesAndPayroll: employeesAndPayrollDE,
        customersAndSuppliers: customersAndSuppliersDE,
        paymentAccounts: paymentAccountsDE,
        monthEndClosing: monthEndClosingDe,
      },
    },
    debug: process.env.NODE_ENV !== 'production',
    lng: 'de',
    fallbackLng: 'en',
    defaultNS: ['common'],
    ns: ['common', 'controlling', 'documents', 'bookkeeping', 'overview'],
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (moment.isMoment(value)) return value.format(format);
        return value;
      },
    },
    react: {
      useSuspense: false,
    },
  });

const lan = ls.get('dash_lan');
if (lan) {
  i18n.changeLanguage(lan);
  moment.locale(lan);
}

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
  ls.set('dash_lan', lng);
  mixpanel.trackEvent('Change Language', { language: lng });
});

export default i18n;

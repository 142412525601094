// @flow
import ReactGA from 'react-ga';
import type AnalyticsInterface from './AnalyticsInterface';

class GoogleAnalytics implements AnalyticsInterface {
  constructor() {
    ReactGA.initialize(process.env.REACT_APP_GA_ID, { gaOptions: { siteSpeedSampleRate: 100 } });
  }

  setUserId = (userId) => {
    ReactGA.set({ userId });
  };

  trackTiming = (category, durationMs, variable, label) => {
    ReactGA.timing({ category, value: durationMs, variable, label });
  };

  trackEvent = (category, action = '', label = '', value = '') => {
    ReactGA.event({ category, action, label, value });
  };

  trackPageChange = (pageName) => {
    ReactGA.pageview(pageName);
  };
}

export default GoogleAnalytics;

// @flow

import { Environment, Observable, RecordSource, Store } from 'relay-runtime';
import {
  authMiddleware,
  cacheMiddleware,
  errorMiddleware,
  loggerMiddleware,
  RelayNetworkLayer,
  retryMiddleware,
  urlMiddleware,
} from 'react-relay-network-modern/es';

import { SubscriptionClientWrapper } from './SubscriptionClientWrapper';
import perfMiddleware from './perfMiddleware';
import relayPipeline from './relayPipeline';
import { getToken } from '../auth0/react-auth0-wrapper';
import blockOpsMutationMiddleware from './blockOpsMutationMiddleware';
import blockMerchantMutationDynamicMiddleware from './blockMerchantMutationDynamicMiddleware';
import bugsnagLoggingMiddleware from './bugsnagLoggingMiddleware';
import { isDev } from '../utils/utils';
import { mockQueryMiddleware } from './mockQueryMiddleware';
import queryNameMiddleware from './queryNameMiddleware';
import { GRAPHQL_GATEWAY, DISABLE_AUTO_UPDATE, WS_URL } from '../utils/config';

const isDevMode = isDev();

let globalCacheRef;
const middlewares = [
  urlMiddleware({
    url: GRAPHQL_GATEWAY,
    headers: {
      Source: 'web_app',
    },
  }),
  isDevMode ? loggerMiddleware() : null,
  blockOpsMutationMiddleware(),
  mockQueryMiddleware(),
  blockMerchantMutationDynamicMiddleware(),
  bugsnagLoggingMiddleware(),
  errorMiddleware(),
  perfMiddleware({
    shouldLog: isDevMode,
    shouldPublish: !isDevMode,
  }),
  !isDevMode &&
    retryMiddleware({
      fetchTimeout: 30000,
      statusCodes: [500, 501, 502, 503, 504, 400, 401, 402, 403, 404],
    }),
  authMiddleware({
    token: getToken,
    allowEmptyToken: false,
  }),
  cacheMiddleware({
    size: 100, // 100 requests
    ttl: 15 * 60 * 1000, // 15 minutes
    clearOnMutation: true, // To make it less problematic, clear the cache on any Mutation
    onInit: (cache) => {
      globalCacheRef = cache;
    },
  }),
  queryNameMiddleware(),
];

export const subscriptionClient =
  !DISABLE_AUTO_UPDATE &&
  new SubscriptionClientWrapper(WS_URL, {
    reconnect: true,
    // Note: await postpones the init message till the token is ready. Currently subscribe function does not wait for token.
    // This might lead to subscribe message sent before the init message which the BE doesn't handle well.
    // For now, it will be fine if requestSubscription is called after a graphql query result is returned.
    connectionParams: async () => ({ token: await getToken() }),
  });

const subscribe = (request, variables) => {
  const subscribeObservable = subscriptionClient.request({
    query: request.text,
    operationName: request.name,
    variables,
  });
  // Important: Convert subscriptions-transport-ws observable type to Relay's
  return Observable.from(subscribeObservable);
};

const network = new RelayNetworkLayer(middlewares, { subscribeFn: subscribe });

const source = new RecordSource();
const store = new Store(source);

const env = new Environment({
  network,
  store,
});

relayPipeline.environment = env;
export const clearRelayCache = () => {
  if (globalCacheRef) {
    globalCacheRef.clear();
  }
};
export default env;

import React from 'react';
import PropTypes from 'prop-types';

const BusinessRoot = ({ props: { business, viewer, ...rest }, children }) => {
  return React.cloneElement(children, { business, viewer, ...rest });
};

BusinessRoot.defaultProps = {
  props: {},
  business: null,
  viewer: null,
};

BusinessRoot.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.array]).isRequired,
  props: PropTypes.shape({
    business: PropTypes.object,
    viewer: PropTypes.object,
  }),
  business: PropTypes.shape({
    __fragments: PropTypes.object,
  }),
  viewer: PropTypes.object,
};

export default BusinessRoot;

/* eslint-disable flowtype/no-types-missing-file-annotation */

import { createStore, Dispatch, Enhancer, Store } from 'react-hooks-global-state';
import { setBugsnagUserBusinessMeta } from './utils/bugsnag';
import { setPageTitle } from './utils/pageUtils';
// eslint-disable-next-line import/no-cycle
import { getAuth0UserType } from './auth0/react-auth0-wrapper';
// eslint-disable-next-line import/no-cycle
import { isTanUser } from './utils/user';

type State = {
  selectedBusinessId: string,
  business: {
    id: string,
    name: string,
    displayName: string,
    legalName: string,
    industryType: string,
    subscriptionStartDate: string,
  },
  businessList: Array,
  userToken: string,
  liquidity: {
    minThreshold: number,
  },
  appVersion: string,
  tutorialShowed: boolean,
};

// eslint-disable flowtype/no-types-missing-file-annotation
type Action =
  | { type: 'setBusinessList', businessList: Array }
  | { type: 'setUserToken', id: string }
  | { type: 'logout' }
  | { type: 'setLiquidity', liquidity: Object }
  | { type: 'setAppVersion', appVersion: string }
  | { type: 'setTutorialShowed', tutorialShowed: boolean };

const defaultState: State = {
  selectedBusinessId: '',
  business: {
    id: '',
    name: '',
    displayName: '',
    legalName: '',
    industryType: '',
    subscriptionStartDate: '',
  },
  userToken: '',
  businessList: [],
  liquidity: {
    minThreshold: 0,
  },
  appVersion: '',
  tutorialShowed: false,
};

const LOCAL_STORAGE_KEY = 'zg_web_data';
const parseState = (str: string | null): State | null => {
  try {
    const state = JSON.parse(str || '');
    if (typeof state.selectedBusinessId !== 'string') throw new Error();
    if (typeof state.liquidity.minThreshold !== 'number') throw new Error();
    if (typeof state.appVersion !== 'string') throw new Error();

    return state;
  } catch (e) {
    return {};
  }
};

const stateFromStorage = parseState(localStorage.getItem(LOCAL_STORAGE_KEY));
const initialState = { ...defaultState, ...stateFromStorage };

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'setBusiness':
      return {
        ...state,
        selectedBusinessId: action.business.id,
        business: action.business,
      };
    case 'setUserToken':
      return {
        ...state,
        userToken: action.userToken,
      };
    case 'setBusinessList':
      return {
        ...state,
        businessList: action.businessList,
      };
    case 'logout':
      return {};
    case 'setLiquidity':
      return {
        ...state,
        liquidity: action.liquidity,
      };
    case 'setAppVersion':
      return {
        ...state,
        appVersion: action.appVersion,
      };
    case 'setTutorialShowed':
      return {
        ...state,
        tutorialShowed: action.tutorialShowed,
      };
    default:
      return state;
  }
};

type Middleware = (store: Store<State, Action>) => (next: Dispatch<Action>) => Dispatch<Action>;
type ApplyMiddleware = (...args: Middleware[]) => Enhancer<State, Action>;

const applyMiddleware: ApplyMiddleware =
  (...args) =>
  (creator) => {
    const [first, ...rest] = args;
    if (!first) return creator;
    // eslint-disable-next-line no-param-reassign
    creator = applyMiddleware(...rest)(creator);
    // eslint-disable-next-line no-shadow
    return (reducer, initialState) => {
      const store = creator(reducer, initialState);
      const dispatch = first(store)(store.dispatch);
      return { ...store, dispatch };
    };
  };

const saveStateToStorage =
  ({ getState }: { getState: () => State }) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    const returnValue = next(action);
    const state = { ...getState() };
    // todo: build config object that will tell which property we shuold save in the local storage
    delete state.business;
    delete state.businessList;
    delete state.userToken;
    delete state.tutorialShowed;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    return returnValue;
  };

export const { GlobalStateProvider, dispatch, useGlobalState } = createStore(
  reducer,
  initialState,
  applyMiddleware(saveStateToStorage),
);

export const clearStorage = () => {
  dispatch({ type: 'logout' });
  // Also make sure storage is cleared immediately
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const setBusiness = (business = {}) => {
  dispatch({ business, type: 'setBusiness' });
  const businessName = business?.displayName;
  setBugsnagUserBusinessMeta(businessName, business?.uid);
  const userType = getAuth0UserType();
  const isTan = isTanUser(userType);
  if (isTan) {
    setPageTitle(businessName);
  }
};

export const setUserToken = (userToken = '') => dispatch({ userToken, type: 'setUserToken' });
export const setBusinessList = (businessList = []) => dispatch({ businessList, type: 'setBusinessList' });
export const setLiquidity = (liquidity = '') => dispatch({ liquidity, type: 'setLiquidity' });
export const setAppVersion = (appVersion = '') => dispatch({ appVersion, type: 'setAppVersion' });
export const setTutorialShowed = (tutorialShowed = '') => dispatch({ tutorialShowed, type: 'setTutorialShowed' });

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import _ from 'lodash';

const getSize = (initial) => {
  let size;

  if (typeof initial === 'number') {
    size = `${initial}px`;
  } else {
    size = initial || '100%';
  }

  return size;
};

// adapted from https://github.com/chenqingspring/react-lottie/blob/master/src/index.js
class AnimationWrapper extends Component {
  constructor(props) {
    super(props);
    this.anim = null;
  }

  componentDidMount() {
    const { options } = this.props;

    const { loop, autoplay, animationData, rendererSettings } = options;

    this.options = {
      container: this.element,
      renderer: 'svg',
      loop: loop !== false,
      autoplay: autoplay !== false,
      animationData,
      rendererSettings,
    };

    this.options = { ...this.options, ...options };

    this.anim = lottie.loadAnimation(this.options);
  }

  componentDidUpdate() {
    this.anim.setSpeed(this.props.speed);
  }

  componentWillUnmount() {
    this.destroy();
    this.options.animationData = null;
    this.anim = null;
  }

  destroy() {
    if (_.isFunction(this.anim.destroy)) {
      this.anim.destroy();
    }
  }

  render() {
    const { width, height, ariaLabel } = this.props;

    const lottieStyles = {
      width: getSize(width),
      height: getSize(height),
      overflow: 'hidden',
      margin: '0 auto',
      outline: 'none',
      ...this.props.style,
    };

    return (
      <div
        className={this.props.className}
        ref={(c) => {
          this.element = c;
        }}
        style={lottieStyles}
        aria-label={ariaLabel}
      />
    );
  }
}

AnimationWrapper.defaultProps = {
  height: null,
  width: null,
  style: null,
  speed: 1,
  className: '',
  ariaLabel: 'animation',
};
AnimationWrapper.propTypes = {
  options: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  speed: PropTypes.number,
  ariaLabel: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
};

export default AnimationWrapper;

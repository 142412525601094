import { logGraphQLToBugsnag } from '../utils/bugsnag';

function bugsnagLoggingMiddleware() {
  return (next) => (req) => {
    const operationName = req.operation.name;
    const variables = req.getVariables();
    const requestType = req.operation.operationKind;
    logGraphQLToBugsnag(operationName, variables, requestType);
    return next(req);
  };
}

export default bugsnagLoggingMiddleware;

import React from 'react';
import LoadingIndicator from '../../components/Base/LoadingIndicator/LoadingIndicator';

export const LoadingPage = () => {
  return (
    <div className="load">
      <LoadingIndicator size={48} />
    </div>
  );
};

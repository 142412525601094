// @flow

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {|
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  JSONString: any,
  UUID: any,
  Date: any,
|};

export type AccountBalanceNode = {|
  ...Node,
  ...{|
    __typename?: 'AccountBalanceNode',
    id: $ElementType<Scalars, 'ID'>,
    balance?: ?$ElementType<Scalars, 'String'>,
    date?: ?$ElementType<Scalars, 'String'>,
    isEstimated?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type AddressNode = {|
  ...Node,
  ...{|
    __typename?: 'AddressNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    streetName: $ElementType<Scalars, 'String'>,
    houseNumber: $ElementType<Scalars, 'String'>,
    postalCode: $ElementType<Scalars, 'String'>,
    city: $ElementType<Scalars, 'String'>,
    country?: ?$ElementType<Scalars, 'String'>,
    comment?: ?$ElementType<Scalars, 'String'>,
    taxadviserSet: TaxAdviserNodeConnection,
    businessSet: BusinessNodeConnection,
    businessemployeeSet: BusinessEmployeeNodeConnection,
    stateCode?: ?$ElementType<Scalars, 'String'>,
    countryCode?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type AddressNodeTaxadviserSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AddressNodeBusinessSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AddressNodeBusinessemployeeSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  email?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export type AddressNodeConnection = {|
  __typename?: 'AddressNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?AddressNodeEdge>,
|};

export type AddressNodeEdge = {|
  __typename?: 'AddressNodeEdge',
  node?: ?AddressNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type AppUserNode = {|
  ...Node,
  ...{|
    __typename?: 'AppUserNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    businesses: BusinessNodeConnection,
    displayName?: ?$ElementType<Scalars, 'String'>,
    email: $ElementType<Scalars, 'String'>,
    appUserToBusiness?: ?AppUserToBusinessNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    businessIds?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type AppUserNodeBusinessesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AppUserNodeAppUserToBusinessArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AppUserNodeConnection = {|
  __typename?: 'AppUserNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?AppUserNodeEdge>,
|};

export type AppUserNodeEdge = {|
  __typename?: 'AppUserNodeEdge',
  node?: ?AppUserNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const AppUserToBusinessAuthorizationScopeInAppValues = Object.freeze({
  All: 'ALL',
  Cep: 'CEP',
  Scan: 'SCAN',
});

export type AppUserToBusinessAuthorizationScopeInApp = $Values<typeof AppUserToBusinessAuthorizationScopeInAppValues>;

export type AppUserToBusinessNode = {|
  ...Node,
  ...{|
    __typename?: 'AppUserToBusinessNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    appUser: AppUserNode,
    business: BusinessNode,
    authorizationScopeInApp: AppUserToBusinessAuthorizationScopeInApp,
  |},
|};

export type AppUserToBusinessNodeConnection = {|
  __typename?: 'AppUserToBusinessNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?AppUserToBusinessNodeEdge>,
|};

export type AppUserToBusinessNodeEdge = {|
  __typename?: 'AppUserToBusinessNodeEdge',
  node?: ?AppUserToBusinessNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BalanceHistoryBalanceCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type BalanceHistoryBalanceCurrency = $Values<typeof BalanceHistoryBalanceCurrencyValues>;

export const BalanceHistoryBalanceSourceValues = Object.freeze({
  BankAccountStatementEndBalance: 'BANK_ACCOUNT_STATEMENT_END_BALANCE',
  BankAccountStatementStartBalance: 'BANK_ACCOUNT_STATEMENT_START_BALANCE',
  Manual: 'MANUAL',
  Unknown: 'UNKNOWN',
});

export type BalanceHistoryBalanceSource = $Values<typeof BalanceHistoryBalanceSourceValues>;

export type BalanceHistoryNode = {|
  ...Node,
  ...{|
    __typename?: 'BalanceHistoryNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    merchantPaymentAccount: MerchantPaymentAccountNode,
    balanceDate: $ElementType<Scalars, 'DateTime'>,
    balanceCurrency: BalanceHistoryBalanceCurrency,
    balance: $ElementType<Scalars, 'Float'>,
    document?: ?DocumentNode,
    balanceSource: BalanceHistoryBalanceSource,
  |},
|};

export type BalanceHistoryNodeConnection = {|
  __typename?: 'BalanceHistoryNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BalanceHistoryNodeEdge>,
|};

export type BalanceHistoryNodeEdge = {|
  __typename?: 'BalanceHistoryNodeEdge',
  node?: ?BalanceHistoryNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookingAccountCaptionEdge = {|
  __typename?: 'BookingAccountCaptionEdge',
  node?: ?BookingAccountCaptionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookingAccountCaptionNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountCaptionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    specification: BookingAccountCaptionSpecification,
    account: $ElementType<Scalars, 'Int'>,
    datevAccountName: $ElementType<Scalars, 'String'>,
    taxAdvisor?: ?TaxAdviserNode,
    business?: ?BusinessNode,
    bookingRuleActions: BookingRuleActionNodeConnection,
  |},
|};

export type BookingAccountCaptionNodeBookingRuleActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookingAccountCaptionNodeConnection = {|
  __typename?: 'BookingAccountCaptionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingAccountCaptionNodeEdge>,
|};

export type BookingAccountCaptionNodeEdge = {|
  __typename?: 'BookingAccountCaptionNodeEdge',
  node?: ?BookingAccountCaptionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BookingAccountCaptionSpecificationValues = Object.freeze({
  Skr03: 'SKR03',
  Skr04: 'SKR04',
});

export type BookingAccountCaptionSpecification = $Values<typeof BookingAccountCaptionSpecificationValues>;

export type BookingAccountNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountNode',
    id: $ElementType<Scalars, 'ID'>,
    accountNumber?: ?$ElementType<Scalars, 'Int'>,
    caption?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type BookingAccountNodeConnection = {|
  __typename?: 'BookingAccountNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingAccountNodeEdge>,
|};

export type BookingAccountNodeEdge = {|
  __typename?: 'BookingAccountNodeEdge',
  node?: ?BookingAccountNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookingAccountsForDebtorsCreditorsNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountsForDebtorsCreditorsNode',
    id: $ElementType<Scalars, 'ID'>,
    bookingAccountRanges?: ?Array<?Array<?$ElementType<Scalars, 'Int'>>>,
    usedBookingAccounts?: ?BookingAccountNodeConnection,
  |},
|};

export type BookingAccountsForDebtorsCreditorsNodeUsedBookingAccountsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookingAccountsForPaymentAccountsNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountsForPaymentAccountsNode',
    id: $ElementType<Scalars, 'ID'>,
    bookingAccountRanges?: ?Array<?Array<?$ElementType<Scalars, 'Int'>>>,
    usedBookingAccounts?: ?Array<?$ElementType<Scalars, 'Int'>>,
  |},
|};

export type BookingAccountsSchemeNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingAccountsSchemeNode',
    id: $ElementType<Scalars, 'ID'>,
    paymentAccounts?: ?BookingAccountsForPaymentAccountsNode,
    debtorsCreditors?: ?BookingAccountsForDebtorsCreditorsNode,
  |},
|};

export type BookingAccountsSchemeNodePaymentAccountsArgs = {|
  accountType: $ElementType<Scalars, 'String'>,
|};

export type BookingAccountsSchemeNodeDebtorsCreditorsArgs = {|
  category: $ElementType<Scalars, 'String'>,
|};

export type BookingInstructionConnection = {|
  __typename?: 'BookingInstructionConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingInstructionEdge>,
|};

export type BookingInstructionEdge = {|
  __typename?: 'BookingInstructionEdge',
  node?: ?BookingInstructionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookingInstructionNode = {|
  __typename?: 'BookingInstructionNode',
  id: $ElementType<Scalars, 'ID'>,
  vatRate?: ?$ElementType<Scalars, 'String'>,
  amount?: ?$ElementType<Scalars, 'Float'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  scenario?: ?BookingScenarioNode,
  bookingsInfo?: ?BookingsInfoNode,
  accountCaption?: ?BookingAccountCaptionNode,
  offsetingAccountCaption?: ?BookingAccountCaptionNode,
|};

export type BookingRecommendationNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingRecommendationNode',
    id: $ElementType<Scalars, 'ID'>,
    confidence?: ?$ElementType<Scalars, 'Float'>,
    scenarioBookings?: ?Array<?BookingScenarioConcreteBookingNode>,
  |},
|};

export type BookingRuleActionInput = {|
  offsettingAccountCaptionId?: ?$ElementType<Scalars, 'String'>,
  highlightForReview?: ?$ElementType<Scalars, 'Boolean'>,
  bookingText?: ?$ElementType<Scalars, 'String'>,
  belegfeld?: ?$ElementType<Scalars, 'String'>,
|};

export type BookingRuleActionNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingRuleActionNode',
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    bookingRule: BookingRuleNode,
    offsettingAccountCaption?: ?BookingAccountCaptionNode,
    highlightForReview: $ElementType<Scalars, 'Boolean'>,
    bookingText?: ?$ElementType<Scalars, 'String'>,
    belegfeld?: ?$ElementType<Scalars, 'String'>,
    id: $ElementType<Scalars, 'ID'>,
  |},
|};

export type BookingRuleActionNodeConnection = {|
  __typename?: 'BookingRuleActionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingRuleActionNodeEdge>,
|};

export type BookingRuleActionNodeEdge = {|
  __typename?: 'BookingRuleActionNodeEdge',
  node?: ?BookingRuleActionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BookingRuleBookkeepingSpecificationValues = Object.freeze({
  Skr03: 'SKR03',
  Skr04: 'SKR04',
});

export type BookingRuleBookkeepingSpecification = $Values<typeof BookingRuleBookkeepingSpecificationValues>;

export type BookingRuleConditionNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingRuleConditionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    bookingRule: BookingRuleNode,
    parameter: $ElementType<Scalars, 'String'>,
    operator: $ElementType<Scalars, 'String'>,
    value: $ElementType<Scalars, 'String'>,
  |},
|};

export type BookingRuleConditionNodeConnection = {|
  __typename?: 'BookingRuleConditionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingRuleConditionNodeEdge>,
|};

export type BookingRuleConditionNodeEdge = {|
  __typename?: 'BookingRuleConditionNodeEdge',
  node?: ?BookingRuleConditionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookingRuleConditionsInput = {|
  parameter?: ?$ElementType<Scalars, 'String'>,
  operator?: ?$ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
|};

export type BookingRuleEdge = {|
  __typename?: 'BookingRuleEdge',
  node?: ?BookingRuleNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BookingRuleEntityDirectionValues = Object.freeze({
  Incoming: 'INCOMING',
  Outgoing: 'OUTGOING',
});

export type BookingRuleEntityDirection = $Values<typeof BookingRuleEntityDirectionValues>;

export const BookingRuleEntityTypeValues = Object.freeze({
  Payable: 'PAYABLE',
  Payment: 'PAYMENT',
});

export type BookingRuleEntityType = $Values<typeof BookingRuleEntityTypeValues>;

export type BookingRuleNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingRuleNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    taxAdvisorBusinessGroup: TaxAdvisorBusinessGroupNode,
    name?: ?$ElementType<Scalars, 'String'>,
    description?: ?$ElementType<Scalars, 'String'>,
    entityType: BookingRuleEntityType,
    entityDirection: BookingRuleEntityDirection,
    ruleType: BookingRuleRuleType,
    bookkeepingSpecification: BookingRuleBookkeepingSpecification,
    priority: $ElementType<Scalars, 'Int'>,
    status: BookingRuleStatus,
    bookings: BookkeepingNodeConnection,
    conditions: BookingRuleConditionNodeConnection,
    action?: ?BookingRuleActionNode,
    ruleDescription?: ?$ElementType<Scalars, 'JSONString'>,
  |},
|};

export type BookingRuleNodeBookingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type BookingRuleNodeConditionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookingRuleNodeConnection = {|
  __typename?: 'BookingRuleNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookingRuleNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookingRuleNodeEdge = {|
  __typename?: 'BookingRuleNodeEdge',
  node?: ?BookingRuleNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BookingRuleRuleTypeValues = Object.freeze({
  Override: 'OVERRIDE',
  Refinement: 'REFINEMENT',
});

export type BookingRuleRuleType = $Values<typeof BookingRuleRuleTypeValues>;

export type BookingRuleSchemaNode = {|
  __typename?: 'BookingRuleSchemaNode',
  conditionsSchema?: ?$ElementType<Scalars, 'JSONString'>,
  actionsSchema?: ?$ElementType<Scalars, 'JSONString'>,
|};

export const BookingRuleStatusValues = Object.freeze({
  Creating: 'CREATING',
  Deleting: 'DELETING',
  Ready: 'READY',
});

export type BookingRuleStatus = $Values<typeof BookingRuleStatusValues>;

export type BookingRuleUpdatedSubscription = {|
  __typename?: 'BookingRuleUpdatedSubscription',
  updatedBookingRule?: ?UpdatedBookingRuleNode,
|};

export const BookingScenarioCategoryValues = Object.freeze({
  AutomobileCosts: 'AUTOMOBILE_COSTS',
  ConsultationCosts: 'CONSULTATION_COSTS',
  OfficeCosts: 'OFFICE_COSTS',
  OperatingCosts: 'OPERATING_COSTS',
  OtherCosts: 'OTHER_COSTS',
  PremisesCost: 'PREMISES_COST',
  SuppliesAndServicesCosts: 'SUPPLIES_AND_SERVICES_COSTS',
});

export type BookingScenarioCategory = $Values<typeof BookingScenarioCategoryValues>;

export type BookingScenarioConcreteBookingNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingScenarioConcreteBookingNode',
    id: $ElementType<Scalars, 'ID'>,
    amount?: ?$ElementType<Scalars, 'Float'>,
    vatRate?: ?$ElementType<Scalars, 'String'>,
    scenario?: ?BookingScenarioNode,
  |},
|};

export const BookingScenarioDomainValues = Object.freeze({
  Cash: 'CASH',
  Employees: 'EMPLOYEES',
  Expenses: 'EXPENSES',
  InvoicesAndReceipts: 'INVOICES_AND_RECEIPTS',
  Other: 'OTHER',
  Payments: 'PAYMENTS',
  SocialInsurance: 'SOCIAL_INSURANCE',
  Statements: 'STATEMENTS',
});

export type BookingScenarioDomain = $Values<typeof BookingScenarioDomainValues>;

export const BookingScenarioExpenseTypeValues = Object.freeze({
  Goods: 'GOODS',
  Service: 'SERVICE',
  Unknown: 'UNKNOWN',
});

export type BookingScenarioExpenseType = $Values<typeof BookingScenarioExpenseTypeValues>;

export type BookingScenarioNode = {|
  ...Node,
  ...{|
    __typename?: 'BookingScenarioNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    name: $ElementType<Scalars, 'String'>,
    description?: ?$ElementType<Scalars, 'String'>,
    domain?: ?BookingScenarioDomain,
    category?: ?BookingScenarioCategory,
    subCategory?: ?BookingScenarioSubCategory,
    recordTypes: Array<$ElementType<Scalars, 'String'>>,
    logicalGroup?: ?$ElementType<Scalars, 'String'>,
    isVatKeyRelevant: $ElementType<Scalars, 'Boolean'>,
    isApplicableForBookingTaskSuggestions: $ElementType<Scalars, 'Boolean'>,
    isLowConfidence: $ElementType<Scalars, 'Boolean'>,
    datevAccountName?: ?$ElementType<Scalars, 'String'>,
    expenseType: BookingScenarioExpenseType,
    legalEntityTypes: Array<$ElementType<Scalars, 'String'>>,
    transactionPayableOrigins: Array<$ElementType<Scalars, 'String'>>,
    entityType: Array<$ElementType<Scalars, 'String'>>,
    invoiceNotTaxCompliant?: ?$ElementType<Scalars, 'Boolean'>,
    isPayableAsPayment?: ?$ElementType<Scalars, 'Boolean'>,
    entityDirections: Array<$ElementType<Scalars, 'String'>>,
    vatRates: Array<$ElementType<Scalars, 'String'>>,
    bookkeepingSet: BookkeepingNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type BookingScenarioNodeBookkeepingSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type BookingScenarioRecommendationsNode = {|
  __typename?: 'BookingScenarioRecommendationsNode',
  scenario?: ?BookingScenarioNode,
  confidence?: ?$ElementType<Scalars, 'Float'>,
|};

export const BookingScenarioSubCategoryValues = Object.freeze({
  Accounting: 'ACCOUNTING',
  AccountingConsultation: 'ACCOUNTING_CONSULTATION',
  Cleaning: 'CLEANING',
  GeneralTravelExpenses: 'GENERAL_TRAVEL_EXPENSES',
  OperatingCosts: 'OPERATING_COSTS',
  OtherItems: 'OTHER_ITEMS',
  RawMaterials: 'RAW_MATERIALS',
});

export type BookingScenarioSubCategory = $Values<typeof BookingScenarioSubCategoryValues>;

export type BookingsInfoNode = {|
  __typename?: 'BookingsInfoNode',
  id: $ElementType<Scalars, 'ID'>,
  source?: ?$ElementType<Scalars, 'String'>,
  document?: ?DocumentNode,
  instructions?: ?BookingInstructionConnection,
|};

export type BookingsInfoNodeInstructionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookkeepingExportBusinessFieldsMutationInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  vatTaxationStatus?: ?$ElementType<Scalars, 'String'>,
  bookkeepingSoftwareId?: ?$ElementType<Scalars, 'String'>,
  datevSubAdvisorNumber?: ?$ElementType<Scalars, 'String'>,
  exportVatKeysPreference?: ?$ElementType<Scalars, 'String'>,
  bookingLogicType?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type BookkeepingExportBusinessFieldsMutationPayload = {|
  __typename?: 'BookkeepingExportBusinessFieldsMutationPayload',
  business?: ?BusinessNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type BookkeepingExportNode = {|
  ...Node,
  ...{|
    __typename?: 'BookkeepingExportNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    month?: ?$ElementType<Scalars, 'String'>,
    business?: ?BusinessNode,
    bookkeeping: BookkeepingNodeConnection,
  |},
|};

export type BookkeepingExportNodeBookkeepingArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type BookkeepingExportNodeConnection = {|
  __typename?: 'BookkeepingExportNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookkeepingExportNodeEdge>,
|};

export type BookkeepingExportNodeEdge = {|
  __typename?: 'BookkeepingExportNodeEdge',
  node?: ?BookkeepingExportNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookkeepingNode = {|
  ...Node,
  ...{|
    __typename?: 'BookkeepingNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    amount: $ElementType<Scalars, 'Float'>,
    bookkeepingType?: ?$ElementType<Scalars, 'String'>,
    isNegativeAmountInConcreteScenario: $ElementType<Scalars, 'Boolean'>,
    account: $ElementType<Scalars, 'Int'>,
    offsetingAccount: $ElementType<Scalars, 'Int'>,
    text: $ElementType<Scalars, 'String'>,
    vatKey?: ?$ElementType<Scalars, 'Int'>,
    business: BusinessNode,
    export?: ?BookkeepingExportNode,
    sourceId?: ?$ElementType<Scalars, 'UUID'>,
    sourceDocumentId: $ElementType<Scalars, 'UUID'>,
    scenario?: ?BookingScenarioNode,
    vatRate?: ?$ElementType<Scalars, 'String'>,
    isReversed: $ElementType<Scalars, 'Boolean'>,
    reconciliationId?: ?$ElementType<Scalars, 'String'>,
    bookingDate: $ElementType<Scalars, 'Date'>,
    solver?: ?BookkeepingSolver,
    isStringent?: ?$ElementType<Scalars, 'Boolean'>,
    isLowConfidence: $ElementType<Scalars, 'Boolean'>,
    reverseChargeCategory?: ?BookkeepingReverseChargeCategory,
    rule?: ?BookingRuleNode,
    transactionIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
    payments?: ?PaymentNodeConnection,
    payables?: ?PayableNodeConnection,
    creditAmount?: ?$ElementType<Scalars, 'Float'>,
    debitAmount?: ?$ElementType<Scalars, 'Float'>,
    directionalAmount?: ?$ElementType<Scalars, 'Float'>,
    isPotentialDoubleBooking?: ?$ElementType<Scalars, 'Boolean'>,
    relevantBusinessTransaction?: ?TransactionNode,
    verificationSource?: ?$ElementType<Scalars, 'String'>,
    reconciliationIdentifier?: ?$ElementType<Scalars, 'String'>,
    uid?: ?$ElementType<Scalars, 'String'>,
    previousMonthDuplicationsDocuments?: ?DocumentNodeConnection,
  |},
|};

export type BookkeepingNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookkeepingNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookkeepingNodePreviousMonthDuplicationsDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BookkeepingNodeConnection = {|
  __typename?: 'BookkeepingNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BookkeepingNodeEdge>,
|};

export type BookkeepingNodeEdge = {|
  __typename?: 'BookkeepingNodeEdge',
  node?: ?BookkeepingNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BookkeepingRecommendationNode = {|
  __typename?: 'BookkeepingRecommendationNode',
  amount?: ?$ElementType<Scalars, 'String'>,
  vatRate?: ?$ElementType<Scalars, 'String'>,
  scenarioRecommendations?: ?Array<?BookingScenarioRecommendationsNode>,
|};

export const BookkeepingReverseChargeCategoryValues = Object.freeze({
  ReceiveServiceFromForeignInvoiceFromEuSupplier: 'RECEIVE_SERVICE_FROM_FOREIGN_INVOICE_FROM_EU_SUPPLIER',
  ReceiveServiceFromForeignInvoiceFromNonEuSupplieR: 'RECEIVE_SERVICE_FROM_FOREIGN_INVOICE_FROM_NON_EU_SUPPLIE_R',
});

export type BookkeepingReverseChargeCategory = $Values<typeof BookkeepingReverseChargeCategoryValues>;

export const BookkeepingSolverValues = Object.freeze({
  Automation: 'AUTOMATION',
  AutomationAligner: 'AUTOMATION_ALIGNER',
  AutomationCustomerMissingPaymentOposClearing: 'AUTOMATION_CUSTOMER_MISSING_PAYMENT_OPOS_CLEARING',
  AutomationDefault: 'AUTOMATION_DEFAULT',
  AutomationMl: 'AUTOMATION_ML',
  AutomationStrategyPrevSupplierBookings: 'AUTOMATION_STRATEGY_PREV_SUPPLIER_BOOKINGS',
  AutomationTipOrDiscount: 'AUTOMATION_TIP_OR_DISCOUNT',
  BookkeeperBookingRule: 'BOOKKEEPER_BOOKING_RULE',
  BookkeeperManual: 'BOOKKEEPER_MANUAL',
  EntityEdit: 'ENTITY_EDIT',
  EntityInvalidation: 'ENTITY_INVALIDATION',
  Manual: 'MANUAL',
  PaymentRemnant: 'PAYMENT_REMNANT',
});

export type BookkeepingSolver = $Values<typeof BookkeepingSolverValues>;

export const BusinessBookingIndustryValues = Object.freeze({
  Agency: 'AGENCY',
  Agriculture: 'AGRICULTURE',
  Catering: 'CATERING',
  Consulting: 'CONSULTING',
  Craftsmen: 'CRAFTSMEN',
  DoctorsAndDentists: 'DOCTORS_AND_DENTISTS',
  ECommerce: 'E_COMMERCE',
  Gastronomy: 'GASTRONOMY',
  HotelIndustry: 'HOTEL_INDUSTRY',
  ManufacturingIndustry: 'MANUFACTURING_INDUSTRY',
  MobilityServices: 'MOBILITY_SERVICES',
  OtherServices: 'OTHER_SERVICES',
  Pharmacy: 'PHARMACY',
  Retail: 'RETAIL',
  TravelAgency: 'TRAVEL_AGENCY',
  Wholesale: 'WHOLESALE',
});

export type BusinessBookingIndustry = $Values<typeof BusinessBookingIndustryValues>;

export const BusinessBookingLogicTypeValues = Object.freeze({
  AlphabeticalAccounts: 'ALPHABETICAL_ACCOUNTS',
  GeneralAccounts: 'GENERAL_ACCOUNTS',
  IndividualAccounts: 'INDIVIDUAL_ACCOUNTS',
});

export type BusinessBookingLogicType = $Values<typeof BusinessBookingLogicTypeValues>;

export const BusinessBookkeepingSpecificationValues = Object.freeze({
  Skr03: 'SKR03',
  Skr04: 'SKR04',
});

export type BusinessBookkeepingSpecification = $Values<typeof BusinessBookkeepingSpecificationValues>;

export const BusinessEmployeeEmploymentStatusValues = Object.freeze({
  Apprentice: 'APPRENTICE',
  Employed: 'EMPLOYED',
  Freelancer: 'FREELANCER',
  Housework: 'HOUSEWORK',
  Management: 'MANAGEMENT',
  MilitaryOrCommunityService: 'MILITARY_OR_COMMUNITY_SERVICE',
  ProfessionalSoldier: 'PROFESSIONAL_SOLDIER',
  PublicSectorEmployee: 'PUBLIC_SECTOR_EMPLOYEE',
  Retired: 'RETIRED',
  SelfEmployed: 'SELF_EMPLOYED',
  Student: 'STUDENT',
  Unemployed: 'UNEMPLOYED',
});

export type BusinessEmployeeEmploymentStatus = $Values<typeof BusinessEmployeeEmploymentStatusValues>;

export type BusinessEmployeeNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessEmployeeNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    firstName: $ElementType<Scalars, 'String'>,
    lastName: $ElementType<Scalars, 'String'>,
    phoneNumber?: ?$ElementType<Scalars, 'String'>,
    email?: ?$ElementType<Scalars, 'String'>,
    employeeNumber?: ?$ElementType<Scalars, 'String'>,
    bankAccount?: ?$ElementType<Scalars, 'String'>,
    isActive: $ElementType<Scalars, 'Boolean'>,
    isOwner: $ElementType<Scalars, 'Boolean'>,
    dateOfBirth?: ?$ElementType<Scalars, 'Date'>,
    placeOfBirth?: ?$ElementType<Scalars, 'String'>,
    birthCountry?: ?$ElementType<Scalars, 'String'>,
    nationality?: ?$ElementType<Scalars, 'String'>,
    address?: ?AddressNode,
    taxIdentificationNumber?: ?$ElementType<Scalars, 'String'>,
    employmentStartingDate?: ?$ElementType<Scalars, 'Date'>,
    employmentEndDate?: ?$ElementType<Scalars, 'Date'>,
    employmentStatus?: ?BusinessEmployeeEmploymentStatus,
    legalRepresentative: $ElementType<Scalars, 'Boolean'>,
    ownershipPercentage?: ?$ElementType<Scalars, 'Float'>,
    ownerBookingAccount?: ?$ElementType<Scalars, 'Int'>,
    datevOwnerId?: ?$ElementType<Scalars, 'Int'>,
    salutation?: ?BusinessEmployeeSalutation,
    business: BusinessNode,
    uid?: ?$ElementType<Scalars, 'String'>,
    ultimateBeneficialOwner?: ?$ElementType<Scalars, 'Boolean'>,
    isAuthorizedForApp?: ?$ElementType<Scalars, 'Boolean'>,
    taxCountryCode?: ?$ElementType<Scalars, 'String'>,
    taxCountry?: ?$ElementType<Scalars, 'String'>,
    birthCountryCode?: ?$ElementType<Scalars, 'String'>,
    nationalityCode?: ?$ElementType<Scalars, 'String'>,
    additionalData?: ?$ElementType<Scalars, 'JSONString'>,
    employmentType?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type BusinessEmployeeNodeConnection = {|
  __typename?: 'BusinessEmployeeNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessEmployeeNodeEdge>,
|};

export type BusinessEmployeeNodeEdge = {|
  __typename?: 'BusinessEmployeeNodeEdge',
  node?: ?BusinessEmployeeNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessEmployeeSalutationValues = Object.freeze({
  Mr: 'MR',
  Ms: 'MS',
});

export type BusinessEmployeeSalutation = $Values<typeof BusinessEmployeeSalutationValues>;

export const BusinessExportVatKeysPreferenceValues = Object.freeze({
  ExcludeFromExport: 'EXCLUDE_FROM_EXPORT',
  IncludeInExport: 'INCLUDE_IN_EXPORT',
});

export type BusinessExportVatKeysPreference = $Values<typeof BusinessExportVatKeysPreferenceValues>;

export const BusinessLegalEntityTypeValues = Object.freeze({
  Ag: 'AG',
  Ek: 'EK',
  Freiberufler: 'FREIBERUFLER',
  Gbr: 'GBR',
  Gmbh: 'GMBH',
  GmbhCoKg: 'GMBH_CO_KG',
  Kg: 'KG',
  Ohg: 'OHG',
  Einzelunternehmen: 'EINZELUNTERNEHMEN',
  Ug: 'UG',
  UgCoKg: 'UG_CO_KG',
  Unknown: 'UNKNOWN',
});

export type BusinessLegalEntityType = $Values<typeof BusinessLegalEntityTypeValues>;

export type BusinessNode = {|
  ...Node,
  ...{|
    __typename?: 'BusinessNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    isActive: $ElementType<Scalars, 'Boolean'>,
    isOnboarded: $ElementType<Scalars, 'Boolean'>,
    isTestOrDemo: $ElementType<Scalars, 'Boolean'>,
    legalName: $ElementType<Scalars, 'String'>,
    displayName: $ElementType<Scalars, 'String'>,
    legalEntityType: BusinessLegalEntityType,
    address?: ?AddressNode,
    vatRegistrationNumber?: ?$ElementType<Scalars, 'String'>,
    taxNumber?: ?$ElementType<Scalars, 'String'>,
    phoneNumber?: ?$ElementType<Scalars, 'String'>,
    email?: ?$ElementType<Scalars, 'String'>,
    outgoingDocumentIndicators?: ?$ElementType<Scalars, 'JSONString'>,
    locale?: ?$ElementType<Scalars, 'String'>,
    bookingIndustry?: ?BusinessBookingIndustry,
    subscriptionStartDate: $ElementType<Scalars, 'Date'>,
    offboardingDate: $ElementType<Scalars, 'Date'>,
    accountingOnboardingDate: $ElementType<Scalars, 'Date'>,
    accountingOffboardingDate: $ElementType<Scalars, 'Date'>,
    bookkeepingSpecification?: ?BusinessBookkeepingSpecification,
    bookkeepingSoftwareId?: ?$ElementType<Scalars, 'String'>,
    datevSubAdvisorNumber?: ?$ElementType<Scalars, 'String'>,
    vatTaxationStatus?: ?BusinessVatTaxationStatus,
    exportVatKeysPreference?: ?BusinessExportVatKeysPreference,
    bookingLogicType: BusinessBookingLogicType,
    taxAdviser?: ?TaxAdviserNode,
    taskActions: TaskActionNodeConnection,
    taxAdvisorBusinessGroups: TaxAdvisorBusinessGroupNodeConnection,
    notes?: ?EntityNoteNodeConnection,
    issues?: ?IssueNodeConnection,
    mecs: MecNodeConnection,
    documents?: ?DocumentNodeConnection,
    employees: BusinessEmployeeNodeConnection,
    paymentAccounts?: ?MerchantPaymentAccountNodeConnection,
    businessTransactions?: ?TransactionNodeConnection,
    appUsers: AppUserNodeConnection,
    appusertobusinessSet: AppUserToBusinessNodeConnection,
    bookingExports: BookkeepingExportNodeConnection,
    bookkeeping: BookkeepingNodeConnection,
    bookingaccountcaptionSet: BookingAccountCaptionNodeConnection,
    suppliers?: ?SupplierNodeConnection,
    customers?: ?CustomerNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    name?: ?$ElementType<Scalars, 'String'>,
    businessEmployees?: ?BusinessEmployeeNodeConnection,
    paymentServiceAccountLimit?: ?$ElementType<Scalars, 'JSONString'>,
    areAllPaymentsFetchedAutomatically?: ?$ElementType<Scalars, 'Boolean'>,
    merchantPaymentAccount?: ?MerchantPaymentAccountNode,
    cashRegisterAccountsCount?: ?$ElementType<Scalars, 'Int'>,
    taxCountryCode?: ?$ElementType<Scalars, 'String'>,
    taxCountry?: ?$ElementType<Scalars, 'String'>,
    questionnaireUrl?: ?$ElementType<Scalars, 'String'>,
    immediateRegistrationFormUrl?: ?$ElementType<Scalars, 'String'>,
    domainsToDocumentTypes?: ?$ElementType<Scalars, 'JSONString'>,
    bookkeepingAccounts?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    paymentServiceStatus?: ?$ElementType<Scalars, 'String'>,
    referralCode?: ?$ElementType<Scalars, 'String'>,
    payments?: ?PaymentNodeConnection,
    payables?: ?PayableNodeConnection,
    activeExperiments?: ?Array<?$ElementType<Scalars, 'String'>>,
    bookingExportsMonthRange?: ?Array<?$ElementType<Scalars, 'String'>>,
    weatherForecast?: ?$ElementType<Scalars, 'JSONString'>,
    shouldShowWebappOnboardingCarousel?: ?$ElementType<Scalars, 'Boolean'>,
    bookingAccountCaptions?: ?BookingAccountCaptionNodeConnection,
    bookingRules?: ?BookingRuleNodeConnection,
    subscriptionPolling?: ?UpdatedEntitiesNode,
    mecEvents?: ?MecNodeConnection,
    mecEvent?: ?MecNode,
    businessOwners?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
    bookingAccountsScheme?: ?BookingAccountsSchemeNode,
  |},
|};

export type BusinessNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeTaxAdvisorBusinessGroupsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  taxAdvisorId?: ?$ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeNotesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  noteContext?: ?$ElementType<Scalars, 'String'>,
  noteContext_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeMecsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  documentType_In?: ?$ElementType<Scalars, 'String'>,
  documentType_Nin?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  payables_Direction?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  recordOccurrenceDateFrom?: ?$ElementType<Scalars, 'String'>,
  recordOccurrenceDateTo?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'String'>,
  hasUnbookableZreports?: ?$ElementType<Scalars, 'Boolean'>,
  flagForLater?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeEmployeesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  email?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export type BusinessNodePaymentAccountsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  accountType?: ?$ElementType<Scalars, 'String'>,
  accountType_In?: ?$ElementType<Scalars, 'String'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isOnlyInRelevancePeriod?: ?$ElementType<Scalars, 'Boolean'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessNodeBusinessTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  minPayablesAmount?: ?$ElementType<Scalars, 'String'>,
  maxPayablesAmount?: ?$ElementType<Scalars, 'String'>,
  minPaymentsAmount?: ?$ElementType<Scalars, 'String'>,
  maxPaymentsAmount?: ?$ElementType<Scalars, 'String'>,
  counterParty?: ?$ElementType<Scalars, 'String'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  reference?: ?$ElementType<Scalars, 'String'>,
  matchToRecord?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeAppUsersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  email?: ?$ElementType<Scalars, 'String'>,
  email_Iexact?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeAppusertobusinessSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeBookingExportsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  month?: ?$ElementType<Scalars, 'Date'>,
|};

export type BusinessNodeBookkeepingArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeBookingaccountcaptionSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessNodeSuppliersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isAutoGeneratedLegalName?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeCustomersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isAutoGeneratedLegalName?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeBusinessEmployeesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
  email?: ?$ElementType<Scalars, 'String'>,
  email_Iexact?: ?$ElementType<Scalars, 'String'>,
  employeeNumber?: ?$ElementType<Scalars, 'String'>,
  isOwner?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isRelevantForPaymentAccountCreation?: ?$ElementType<Scalars, 'Boolean'>,
  isRelevantForPaymentAccountTermsAndConditions?: ?$ElementType<Scalars, 'Boolean'>,
  employmentType?: ?$ElementType<Scalars, 'String'>,
  employeeName?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeMerchantPaymentAccountArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeDomainsToDocumentTypesArgs = {|
  domain?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  dateOccurred_Gte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred_Lte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessNodeWeatherForecastArgs = {|
  q?: ?$ElementType<Scalars, 'String'>,
  days?: ?$ElementType<Scalars, 'String'>,
  lang?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeBookingAccountCaptionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeBookingRulesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  entityType?: ?$ElementType<Scalars, 'String'>,
  entityDirection?: ?$ElementType<Scalars, 'String'>,
  ruleType?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  status_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeSubscriptionPollingArgs = {|
  key: SubscriptionPolingTypeEnum,
  cursor?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeMecEventsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  month?: ?$ElementType<Scalars, 'Date'>,
  month_In?: ?$ElementType<Scalars, 'Date'>,
  month_Lt?: ?$ElementType<Scalars, 'Date'>,
  month_Lte?: ?$ElementType<Scalars, 'Date'>,
  month_Gt?: ?$ElementType<Scalars, 'Date'>,
  month_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessNodeMecEventArgs = {|
  eventId?: ?$ElementType<Scalars, 'ID'>,
|};

export type BusinessNodeConnection = {|
  __typename?: 'BusinessNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?BusinessNodeEdge>,
|};

export type BusinessNodeEdge = {|
  __typename?: 'BusinessNodeEdge',
  node?: ?BusinessNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const BusinessVatTaxationStatusValues = Object.freeze({
  Actual: 'ACTUAL',
  Target: 'TARGET',
});

export type BusinessVatTaxationStatus = $Values<typeof BusinessVatTaxationStatusValues>;

export type ClientProfileOtherFieldsMutationInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  businessOwners: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ClientProfileOtherFieldsMutationPayload = {|
  __typename?: 'ClientProfileOtherFieldsMutationPayload',
  business?: ?BusinessNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type CustomerNode = {|
  ...Node,
  ...{|
    __typename?: 'CustomerNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    legalName: $ElementType<Scalars, 'String'>,
    isAutoGeneratedLegalName: $ElementType<Scalars, 'Boolean'>,
    isLegalNameEditedByTheUser: $ElementType<Scalars, 'Boolean'>,
    account?: ?$ElementType<Scalars, 'Int'>,
    documents: DocumentNodeConnection,
    payments: PaymentNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    accountCaption?: ?$ElementType<Scalars, 'String'>,
    numberOfRules?: ?$ElementType<Scalars, 'Int'>,
  |},
|};

export type CustomerNodeDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type CustomerNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type CustomerNodeConnection = {|
  __typename?: 'CustomerNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?CustomerNodeEdge>,
|};

export type CustomerNodeEdge = {|
  __typename?: 'CustomerNodeEdge',
  node?: ?CustomerNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type DeleteBookingRuleInput = {|
  bookingRuleId: $ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBookingRulePayload = {|
  __typename?: 'DeleteBookingRulePayload',
  bookingRule?: ?BookingRuleEdge,
  deletedBookingRuleId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type DeletePaymentInput = {|
  paymentId: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeletePaymentPayload = {|
  __typename?: 'DeletePaymentPayload',
  eventPaymentAccount?: ?EventPaymentAccountNode,
  payment?: ?PaymentNodeEdge,
  deletedPaymentId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNode = {|
  ...Node,
  ...{|
    __typename?: 'DocumentNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    mec: MecNode,
    documentType?: ?$ElementType<Scalars, 'String'>,
    fileName?: ?$ElementType<Scalars, 'String'>,
    data: $ElementType<Scalars, 'JSONString'>,
    uploadStatus: DocumentUploadStatus,
    exportId?: ?$ElementType<Scalars, 'UUID'>,
    supplier?: ?SupplierNode,
    customer?: ?CustomerNode,
    accountBalances: BalanceHistoryNodeConnection,
    payments: PaymentNodeConnection,
    payables: PayableNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    fullUrl?: ?$ElementType<Scalars, 'String'>,
    thumbnailUrl?: ?$ElementType<Scalars, 'String'>,
    bigThumbnailUrl?: ?$ElementType<Scalars, 'String'>,
    taskActions?: ?TaskActionNodeConnection,
    requiredScheme?: ?$ElementType<Scalars, 'JSONString'>,
    requiredSchemeForDocument?: ?$ElementType<Scalars, 'JSONString'>,
    fullSchemeForDocument?: ?$ElementType<Scalars, 'JSONString'>,
    paymentStatus?: ?$ElementType<Scalars, 'String'>,
    shouldExtract?: ?$ElementType<Scalars, 'Boolean'>,
    statementBankAccount?: ?MerchantPaymentAccountNode,
    bookingRecommendations?: ?Array<?BookkeepingRecommendationNode>,
    isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
    isReadyForBooking?: ?$ElementType<Scalars, 'Boolean'>,
    complianceIssues?: ?Array<?$ElementType<Scalars, 'String'>>,
    bookings?: ?BookingsInfoNode,
    isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
    extractedFields?: ?$ElementType<Scalars, 'JSONString'>,
    onlineExtractionData?: ?$ElementType<Scalars, 'JSONString'>,
    fieldSources?: ?$ElementType<Scalars, 'JSONString'>,
  |},
|};

export type DocumentNodeAccountBalancesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type DocumentNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type DocumentNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type DocumentNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNodeRequiredSchemeArgs = {|
  documentType?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNodeBookingRecommendationsArgs = {|
  counterPartyId?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNodeOnlineExtractionDataArgs = {|
  classifyDocument?: ?$ElementType<Scalars, 'Boolean'>,
  extractFields?: ?$ElementType<Scalars, 'Boolean'>,
  returnOcrText?: ?$ElementType<Scalars, 'Boolean'>,
  documentTypesToExtract?: ?Array<?$ElementType<Scalars, 'String'>>,
  userSelectedDocumentType?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentNodeConnection = {|
  __typename?: 'DocumentNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?DocumentNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type DocumentNodeEdge = {|
  __typename?: 'DocumentNodeEdge',
  node?: ?DocumentNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type DocumentUpdatedSubscription = {|
  __typename?: 'DocumentUpdatedSubscription',
  updatedDocument?: ?UpdatedDocumentNode,
|};

export const DocumentUploadStatusValues = Object.freeze({
  Booking: 'BOOKING',
  CreatingEntities: 'CREATING_ENTITIES',
  Done: 'DONE',
  Extracting: 'EXTRACTING',
  Failed: 'FAILED',
  GettingOcr: 'GETTING_OCR',
  Linking: 'LINKING',
  PaymentCreated: 'PAYMENT_CREATED',
  ProcessingExtraction: 'PROCESSING_EXTRACTION',
});

export type DocumentUploadStatus = $Values<typeof DocumentUploadStatusValues>;

export type EntityCreatorUserNode = {|
  ...Node,
  ...{|
    __typename?: 'EntityCreatorUserNode',
    id: $ElementType<Scalars, 'ID'>,
    displayName?: ?$ElementType<Scalars, 'String'>,
    group?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type EntityNode = PaymentNode | PayableNode | TransactionNode | MerchantPaymentAccountNode | DocumentNode;

export type EntityNoteNode = {|
  ...Node,
  ...{|
    __typename?: 'EntityNoteNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business?: ?BusinessNode,
    sourceEntityId: $ElementType<Scalars, 'UUID'>,
    note: $ElementType<Scalars, 'String'>,
    noteContext: EntityNoteNoteContext,
  |},
|};

export type EntityNoteNodeConnection = {|
  __typename?: 'EntityNoteNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?EntityNoteNodeEdge>,
|};

export type EntityNoteNodeEdge = {|
  __typename?: 'EntityNoteNodeEdge',
  node?: ?EntityNoteNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const EntityNoteNoteContextValues = Object.freeze({
  BookkeeperMarkedDocumentReady: 'BOOKKEEPER_MARKED_DOCUMENT_READY',
  BookkeeperMarkedPaymentDone: 'BOOKKEEPER_MARKED_PAYMENT_DONE',
  NedAreTransactionsBeforeOnboardingAnalysed: 'NED_ARE_TRANSACTIONS_BEFORE_ONBOARDING_ANALYSED',
  OposHandledInDatev: 'OPOS_HANDLED_IN_DATEV',
  RecordExtractionApprovedByAccounting: 'RECORD_EXTRACTION_APPROVED_BY_ACCOUNTING',
  RecordExtractionVerified: 'RECORD_EXTRACTION_VERIFIED',
  BookingTasks: 'BOOKING_TASKS',
  DjangoAdmin: 'DJANGO_ADMIN',
  TaxAdvisorClientContractSigned: 'TAX_ADVISOR_CLIENT_CONTRACT_SIGNED',
  TaxAdvisorCompletedOnboardingCall: 'TAX_ADVISOR_COMPLETED_ONBOARDING_CALL',
  TaxAdvisorClientProfile: 'TAX_ADVISOR_CLIENT_PROFILE',
});

export type EntityNoteNoteContext = $Values<typeof EntityNoteNoteContextValues>;

export type EventPaymentAccountConnection = {|
  __typename?: 'EventPaymentAccountConnection',
  pageInfo: PageInfo,
  edges: Array<?EventPaymentAccountEdge>,
|};

export type EventPaymentAccountEdge = {|
  __typename?: 'EventPaymentAccountEdge',
  node?: ?EventPaymentAccountNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type EventPaymentAccountNode = {|
  __typename?: 'EventPaymentAccountNode',
  id: $ElementType<Scalars, 'ID'>,
  paymentAccount?: ?MerchantPaymentAccountNode,
  event?: ?MecNode,
  eventBalanceHistory?: ?Array<?BalanceHistoryNode>,
  eventPayments?: ?PaymentNodeConnection,
  totalPaymentsDirectedAmount?: ?$ElementType<Scalars, 'Float'>,
|};

export type EventPaymentAccountNodeEventBalanceHistoryArgs = {|
  fromDate?: ?$ElementType<Scalars, 'Date'>,
  untilDate?: ?$ElementType<Scalars, 'Date'>,
  toleranceInDays?: ?$ElementType<Scalars, 'Int'>,
|};

export type EventPaymentAccountNodeEventPaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type EventType = {|
  __typename?: 'EventType',
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAppUserInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  appScope?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAppUserPayload = {|
  __typename?: 'IntroduceAppUserPayload',
  appUser?: ?AppUserNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBookingRuleInput = {|
  taxAdvisorBusinessGroupId?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  entityType?: ?$ElementType<Scalars, 'String'>,
  entityDirection?: ?$ElementType<Scalars, 'String'>,
  priority?: ?$ElementType<Scalars, 'Int'>,
  conditions?: ?Array<?BookingRuleConditionsInput>,
  action?: ?BookingRuleActionInput,
  invalidateManualActions?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBookingRulePayload = {|
  __typename?: 'IntroduceBookingRulePayload',
  bookingRule?: ?BookingRuleEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessBookingAccountCaptionInput = {|
  datevAccountName?: ?$ElementType<Scalars, 'String'>,
  taxAdvisorId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessBookingAccountCaptionPayload = {|
  __typename?: 'IntroduceBusinessBookingAccountCaptionPayload',
  bookingAccountCaption?: ?BookingAccountCaptionEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCsvBankAccountStatementInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  accountId?: ?$ElementType<Scalars, 'String'>,
  csvBase64?: ?$ElementType<Scalars, 'String'>,
  fileName?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCsvBankAccountStatementPayload = {|
  __typename?: 'IntroduceCsvBankAccountStatementPayload',
  document?: ?DocumentNode,
  resultType?: ?$ElementType<Scalars, 'String'>,
  csvPaymentsCount?: ?$ElementType<Scalars, 'Int'>,
  newPaymentCount?: ?$ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCustomerInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  customerId?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCustomerPayload = {|
  __typename?: 'IntroduceCustomerPayload',
  customer?: ?CustomerNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceEntityNote = {|
  __typename?: 'IntroduceEntityNote',
  entityNote?: ?EntityNoteNode,
|};

export type IntroduceManualAccountBalanceInput = {|
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
  balance?: ?$ElementType<Scalars, 'Float'>,
  balanceDate?: ?$ElementType<Scalars, 'Date'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceManualAccountBalancePayload = {|
  __typename?: 'IntroduceManualAccountBalancePayload',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
  balanceHistory?: ?BalanceHistoryNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceMerchantPaymentAccountInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  accountType: $ElementType<Scalars, 'String'>,
  displayName: $ElementType<Scalars, 'String'>,
  bankAccount?: ?$ElementType<Scalars, 'String'>,
  predefinedBookingAccount: $ElementType<Scalars, 'Int'>,
  activeSince?: ?$ElementType<Scalars, 'Date'>,
  activeUntil?: ?$ElementType<Scalars, 'Date'>,
  isCashRegisterPointOfSale?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceMerchantPaymentAccountPayload = {|
  __typename?: 'IntroduceMerchantPaymentAccountPayload',
  merchantPaymentAccount?: ?MerchantPaymentAccountEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceNedEntityNoteInput = {|
  entityId: $ElementType<Scalars, 'String'>,
  entityType: $ElementType<Scalars, 'String'>,
  note: $ElementType<Scalars, 'String'>,
  noteContext: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceNedEntityNotePayload = {|
  __typename?: 'IntroduceNedEntityNotePayload',
  entityNote?: ?EntityNoteNode,
  entity?: ?EntityNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroducePaymentInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  paymentId?: ?$ElementType<Scalars, 'String'>,
  paymentReference?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  amount?: ?$ElementType<Scalars, 'Float'>,
  merchantPaymentAccountId?: ?$ElementType<Scalars, 'String'>,
  counterPartyIban?: ?$ElementType<Scalars, 'String'>,
  counterPartyName?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroducePaymentPayload = {|
  __typename?: 'IntroducePaymentPayload',
  eventPaymentAccount?: ?EventPaymentAccountNode,
  paymentEdge?: ?PaymentNodeEdge,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroducePaymentPayloadPaymentEdgeArgs = {|
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSupplierInput = {|
  businessId?: ?$ElementType<Scalars, 'String'>,
  supplierId?: ?$ElementType<Scalars, 'String'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSupplierPayload = {|
  __typename?: 'IntroduceSupplierPayload',
  supplier?: ?SupplierNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IssueNode = {|
  ...Node,
  ...{|
    __typename?: 'IssueNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    issueType?: ?$ElementType<Scalars, 'String'>,
    sourceEntityId: $ElementType<Scalars, 'UUID'>,
    isOpen: $ElementType<Scalars, 'Boolean'>,
    business: BusinessNode,
    closedAt?: ?$ElementType<Scalars, 'DateTime'>,
    issueTaskActions: TaskActionNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    transaction?: ?TransactionNode,
    merchantPaymentAccount?: ?MerchantPaymentAccountNode,
    businessEmployee?: ?BusinessEmployeeNode,
    payable?: ?PayableNode,
    payment?: ?PaymentNode,
  |},
|};

export type IssueNodeIssueTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type IssueNodeConnection = {|
  __typename?: 'IssueNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?IssueNodeEdge>,
|};

export type IssueNodeEdge = {|
  __typename?: 'IssueNodeEdge',
  node?: ?IssueNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type JsonSchemeNode = {|
  ...Node,
  ...{|
    __typename?: 'JsonSchemeNode',
    id: $ElementType<Scalars, 'ID'>,
    scheme?: ?$ElementType<Scalars, 'JSONString'>,
    name?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type LinkDocumentInput = {|
  documentId: $ElementType<Scalars, 'String'>,
  linkingData?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type LinkDocumentPayload = {|
  __typename?: 'LinkDocumentPayload',
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type LinkPaymentInput = {|
  paymentId: $ElementType<Scalars, 'String'>,
  linkingData?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type LinkPaymentPayload = {|
  __typename?: 'LinkPaymentPayload',
  payment?: ?PaymentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type MandatoryBookkeepingBusinessFieldsMutationInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  bookkeepingSpecification?: ?$ElementType<Scalars, 'String'>,
  bookingIndustry?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MandatoryBookkeepingBusinessFieldsMutationPayload = {|
  __typename?: 'MandatoryBookkeepingBusinessFieldsMutationPayload',
  business?: ?BusinessNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkDocumentAsDuplicateInput = {|
  documentId?: ?$ElementType<Scalars, 'String'>,
  originalDocumentId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkDocumentAsDuplicatePayload = {|
  __typename?: 'MarkDocumentAsDuplicatePayload',
  document?: ?DocumentNode,
  originalDocument?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MecNode = {|
  ...Node,
  ...{|
    __typename?: 'MECNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    month: $ElementType<Scalars, 'Date'>,
    business: BusinessNode,
    isOpen?: ?$ElementType<Scalars, 'Boolean'>,
    stepName: MecStepName,
    documents?: ?DocumentNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    payments?: ?PaymentNodeConnection,
    eventPaymentAccounts?: ?EventPaymentAccountConnection,
    payables?: ?PayableNodeConnection,
    unbookedDocumentsCount?: ?$ElementType<Scalars, 'Int'>,
    bookingSuggestionsCount?: ?$ElementType<Scalars, 'Int'>,
    exportPreviewLink?: ?$ElementType<Scalars, 'String'>,
    paymentsCount?: ?$ElementType<Scalars, 'Int'>,
    documentsCount?: ?$ElementType<Scalars, 'Int'>,
    isSkipped?: ?$ElementType<Scalars, 'Boolean'>,
    isDone?: ?$ElementType<Scalars, 'Boolean'>,
    spawn?: ?MecNode,
  |},
|};

export type MecNodeDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  documentType_In?: ?$ElementType<Scalars, 'String'>,
  documentType_Nin?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  payables_Direction?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  recordOccurrenceDateFrom?: ?$ElementType<Scalars, 'String'>,
  recordOccurrenceDateTo?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'String'>,
  hasUnbookableZreports?: ?$ElementType<Scalars, 'Boolean'>,
  flagForLater?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type MecNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type MecNodeEventPaymentAccountsArgs = {|
  accountType_In?: ?$ElementType<Scalars, 'String'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MecNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  dateOccurred_Gte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred_Lte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type MecNodePaymentsCountArgs = {|
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type MecNodeDocumentsCountArgs = {|
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type MecNodeSpawnArgs = {|
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type MecNodeConnection = {|
  __typename?: 'MECNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?MecNodeEdge>,
|};

export type MecNodeEdge = {|
  __typename?: 'MECNodeEdge',
  node?: ?MecNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const MecStepNameValues = Object.freeze({
  ExportBookingsStep: 'EXPORT_BOOKINGS_STEP',
  ProcessDocumentsStep: 'PROCESS_DOCUMENTS_STEP',
  ProcessPaymentsStep: 'PROCESS_PAYMENTS_STEP',
  UploadMonthlyDocumentsStep: 'UPLOAD_MONTHLY_DOCUMENTS_STEP',
  ValidatePaymentsCompletenessStep: 'VALIDATE_PAYMENTS_COMPLETENESS_STEP',
});

export type MecStepName = $Values<typeof MecStepNameValues>;

export const MerchantPaymentAccountBalanceCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type MerchantPaymentAccountBalanceCurrency = $Values<typeof MerchantPaymentAccountBalanceCurrencyValues>;

export const MerchantPaymentAccountCreditLineCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type MerchantPaymentAccountCreditLineCurrency = $Values<typeof MerchantPaymentAccountCreditLineCurrencyValues>;

export type MerchantPaymentAccountEdge = {|
  __typename?: 'MerchantPaymentAccountEdge',
  node?: ?MerchantPaymentAccountNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type MerchantPaymentAccountNode = {|
  ...Node,
  ...{|
    __typename?: 'MerchantPaymentAccountNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    accountType?: ?$ElementType<Scalars, 'String'>,
    displayName?: ?$ElementType<Scalars, 'String'>,
    bankAccount?: ?$ElementType<Scalars, 'String'>,
    predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
    balanceDate?: ?$ElementType<Scalars, 'DateTime'>,
    balanceCurrency: MerchantPaymentAccountBalanceCurrency,
    balance?: ?$ElementType<Scalars, 'Float'>,
    creditLineCurrency: MerchantPaymentAccountCreditLineCurrency,
    creditLine: $ElementType<Scalars, 'Float'>,
    isCashRegisterPointOfSale: $ElementType<Scalars, 'Boolean'>,
    activeSince?: ?$ElementType<Scalars, 'Date'>,
    activeUntil: $ElementType<Scalars, 'Date'>,
    bankCompany?: ?$ElementType<Scalars, 'String'>,
    balanceHistory: BalanceHistoryNodeConnection,
    payments?: ?PaymentNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    bankAccountCredentialsForm?: ?$ElementType<Scalars, 'JSONString'>,
    isActive?: ?$ElementType<Scalars, 'Boolean'>,
    supportedTanSchemes?: ?$ElementType<Scalars, 'JSONString'>,
    averageMonthlyPaymentsByDirection?: ?$ElementType<Scalars, 'JSONString'>,
    monthEndBalance?: ?MonthEndBalancesNode,
    isPaypalAccount?: ?$ElementType<Scalars, 'Boolean'>,
    lastCsvPaymentDate?: ?$ElementType<Scalars, 'String'>,
    predefinedBookingAccountUpdateInProgress?: ?$ElementType<Scalars, 'Int'>,
    isCorrectionNeeded?: ?$ElementType<Scalars, 'Boolean'>,
    createdByUser?: ?EntityCreatorUserNode,
  |},
|};

export type MerchantPaymentAccountNodeBalanceHistoryArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type MerchantPaymentAccountNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type MerchantPaymentAccountNodeMonthEndBalanceArgs = {|
  month?: ?$ElementType<Scalars, 'Date'>,
|};

export type MerchantPaymentAccountNodeLastCsvPaymentDateArgs = {|
  month?: ?$ElementType<Scalars, 'Date'>,
|};

export type MerchantPaymentAccountNodeConnection = {|
  __typename?: 'MerchantPaymentAccountNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?MerchantPaymentAccountNodeEdge>,
|};

export type MerchantPaymentAccountNodeEdge = {|
  __typename?: 'MerchantPaymentAccountNodeEdge',
  node?: ?MerchantPaymentAccountNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type MonthEndBalancesNode = {|
  ...Node,
  ...{|
    __typename?: 'MonthEndBalancesNode',
    id: $ElementType<Scalars, 'ID'>,
    accountName?: ?$ElementType<Scalars, 'String'>,
    predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
    isFigoOnboarded?: ?$ElementType<Scalars, 'Boolean'>,
    sourceName?: ?$ElementType<Scalars, 'String'>,
    monthEndBalance?: ?AccountBalanceNode,
    monthStartBalance?: ?AccountBalanceNode,
    balanceDiff?: ?$ElementType<Scalars, 'String'>,
    bookingBalance?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type MoveMecToNextStepInput = {|
  mecId?: ?$ElementType<Scalars, 'String'>,
  finishedStepName?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MoveMecToNextStepPayload = {|
  __typename?: 'MoveMECToNextStepPayload',
  mec?: ?MecNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type Mutation = {|
  __typename?: 'Mutation',
  uploadNewDocuments?: ?UploadNewDocuments,
  introduceAppUser?: ?IntroduceAppUserPayload,
  introduceMerchantPaymentAccount?: ?IntroduceMerchantPaymentAccountPayload,
  updateMerchantPaymentAccount?: ?UpdateMerchantPaymentAccountPayload,
  mandatoryBookkeepingBusinessFieldsMutation?: ?MandatoryBookkeepingBusinessFieldsMutationPayload,
  clientProfileOtherFieldsMutation?: ?ClientProfileOtherFieldsMutationPayload,
  bookkeepingExportBusinessFieldsMutation?: ?BookkeepingExportBusinessFieldsMutationPayload,
  introduceManualAccountBalance?: ?IntroduceManualAccountBalancePayload,
  introduceEntityNote?: ?IntroduceEntityNote,
  introduceNedEntityNote?: ?IntroduceNedEntityNotePayload,
  introduceBookingRule?: ?IntroduceBookingRulePayload,
  deleteBookingRule?: ?DeleteBookingRulePayload,
  verifyDoubleBooking?: ?VerifyDoubleBookingPayload,
  introduceBusinessBookingAccountCaption?: ?IntroduceBusinessBookingAccountCaptionPayload,
  uploadDocument?: ?UploadDocumentPayload,
  updateLabelerDocumentData?: ?UpdateLabelerDocumentDataPayload,
  onOcrProcessingFinish?: ?OnOcrProcessingFinishPayload,
  introduceCsvBankAccountStatement?: ?IntroduceCsvBankAccountStatementPayload,
  updateDocument?: ?UpdateDocumentPayload,
  markDocumentAsDuplicate?: ?MarkDocumentAsDuplicatePayload,
  introducePayment?: ?IntroducePaymentPayload,
  updatePaymentDetails?: ?UpdatePaymentDetailsPayload,
  deletePayment?: ?DeletePaymentPayload,
  updatePaymentBookings?: ?UpdatePaymentBookingsPayload,
  linkPayment?: ?LinkPaymentPayload,
  linkDocument?: ?LinkDocumentPayload,
  introduceSupplier?: ?IntroduceSupplierPayload,
  introduceCustomer?: ?IntroduceCustomerPayload,
  skipMec?: ?SkipMecPayload,
  moveMecToNextStep?: ?MoveMecToNextStepPayload,
|};

export type MutationUploadNewDocumentsArgs = {|
  apiType?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  splitToSinglePages?: ?$ElementType<Scalars, 'Boolean'>,
  zeitgoldBusinessId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceAppUserArgs = {|
  input: IntroduceAppUserInput,
|};

export type MutationIntroduceMerchantPaymentAccountArgs = {|
  input: IntroduceMerchantPaymentAccountInput,
|};

export type MutationUpdateMerchantPaymentAccountArgs = {|
  input: UpdateMerchantPaymentAccountInput,
|};

export type MutationMandatoryBookkeepingBusinessFieldsMutationArgs = {|
  input: MandatoryBookkeepingBusinessFieldsMutationInput,
|};

export type MutationClientProfileOtherFieldsMutationArgs = {|
  input: ClientProfileOtherFieldsMutationInput,
|};

export type MutationBookkeepingExportBusinessFieldsMutationArgs = {|
  input: BookkeepingExportBusinessFieldsMutationInput,
|};

export type MutationIntroduceManualAccountBalanceArgs = {|
  input: IntroduceManualAccountBalanceInput,
|};

export type MutationIntroduceEntityNoteArgs = {|
  businessId: $ElementType<Scalars, 'String'>,
  entityId: $ElementType<Scalars, 'String'>,
  entityType: $ElementType<Scalars, 'String'>,
  note: $ElementType<Scalars, 'String'>,
  noteContext: $ElementType<Scalars, 'String'>,
|};

export type MutationIntroduceNedEntityNoteArgs = {|
  input: IntroduceNedEntityNoteInput,
|};

export type MutationIntroduceBookingRuleArgs = {|
  input: IntroduceBookingRuleInput,
|};

export type MutationDeleteBookingRuleArgs = {|
  input: DeleteBookingRuleInput,
|};

export type MutationVerifyDoubleBookingArgs = {|
  input: VerifyDoubleBookingInput,
|};

export type MutationIntroduceBusinessBookingAccountCaptionArgs = {|
  input: IntroduceBusinessBookingAccountCaptionInput,
|};

export type MutationUploadDocumentArgs = {|
  input: UploadDocumentInput,
|};

export type MutationUpdateLabelerDocumentDataArgs = {|
  input: UpdateLabelerDocumentDataInput,
|};

export type MutationOnOcrProcessingFinishArgs = {|
  input: OnOcrProcessingFinishInput,
|};

export type MutationIntroduceCsvBankAccountStatementArgs = {|
  input: IntroduceCsvBankAccountStatementInput,
|};

export type MutationUpdateDocumentArgs = {|
  input: UpdateDocumentInput,
|};

export type MutationMarkDocumentAsDuplicateArgs = {|
  input: MarkDocumentAsDuplicateInput,
|};

export type MutationIntroducePaymentArgs = {|
  input: IntroducePaymentInput,
|};

export type MutationUpdatePaymentDetailsArgs = {|
  input: UpdatePaymentDetailsInput,
|};

export type MutationDeletePaymentArgs = {|
  input: DeletePaymentInput,
|};

export type MutationUpdatePaymentBookingsArgs = {|
  input: UpdatePaymentBookingsInput,
|};

export type MutationLinkPaymentArgs = {|
  input: LinkPaymentInput,
|};

export type MutationLinkDocumentArgs = {|
  input: LinkDocumentInput,
|};

export type MutationIntroduceSupplierArgs = {|
  input: IntroduceSupplierInput,
|};

export type MutationIntroduceCustomerArgs = {|
  input: IntroduceCustomerInput,
|};

export type MutationSkipMecArgs = {|
  input: SkipMecInput,
|};

export type MutationMoveMecToNextStepArgs = {|
  input: MoveMecToNextStepInput,
|};

export type Node = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type OnOcrProcessingFinishInput = {|
  documentId?: ?$ElementType<Scalars, 'String'>,
  ocrText?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type OnOcrProcessingFinishPayload = {|
  __typename?: 'OnOcrProcessingFinishPayload',
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type PageInfo = {|
  __typename?: 'PageInfo',
  hasNextPage: $ElementType<Scalars, 'Boolean'>,
  hasPreviousPage: $ElementType<Scalars, 'Boolean'>,
  startCursor?: ?$ElementType<Scalars, 'String'>,
  endCursor?: ?$ElementType<Scalars, 'String'>,
|};

export type PayableNode = {|
  ...Node,
  ...{|
    __typename?: 'PayableNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    dateOccurred: $ElementType<Scalars, 'Date'>,
    payableIdentifier?: ?$ElementType<Scalars, 'String'>,
    paymentReference?: ?$ElementType<Scalars, 'String'>,
    amount: $ElementType<Scalars, 'Float'>,
    amountToReconcile: $ElementType<Scalars, 'Float'>,
    vatRatesAmountsGross?: ?$ElementType<Scalars, 'JSONString'>,
    businessTransaction?: ?TransactionNode,
    document?: ?DocumentNode,
    direction?: ?$ElementType<Scalars, 'String'>,
    payableOrigin: PayablePayableOrigin,
    originalPayableAmountCurrency: PayableOriginalPayableAmountCurrency,
    originalPayableAmount?: ?$ElementType<Scalars, 'Float'>,
    uid?: ?$ElementType<Scalars, 'String'>,
    vatAmount?: ?$ElementType<Scalars, 'Float'>,
    amountWithDiscount?: ?$ElementType<Scalars, 'Float'>,
    directedAmount?: ?$ElementType<Scalars, 'Float'>,
    vatCompliantProblems?: ?Array<?$ElementType<Scalars, 'String'>>,
    replacementReceiptScheme?: ?$ElementType<Scalars, 'JSONString'>,
    invoiceNumber?: ?$ElementType<Scalars, 'String'>,
    taskActions?: ?TaskActionNodeConnection,
    dueDate?: ?$ElementType<Scalars, 'String'>,
    transactions?: ?TransactionNode,
    transactionIssues?: ?IssueNodeConnection,
    verificationSource?: ?$ElementType<Scalars, 'String'>,
    isHighlightedForReview?: ?$ElementType<Scalars, 'Boolean'>,
    bookings?: ?BookkeepingNodeConnection,
  |},
|};

export type PayableNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type PayableNodeTransactionIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type PayableNodeBookingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  bookingDate_Year?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  export_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  export_Month?: ?$ElementType<Scalars, 'Date'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isReversed?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  relatedAccount?: ?$ElementType<Scalars, 'String'>,
  isContributingToTaxType?: ?$ElementType<Scalars, 'String'>,
|};

export type PayableNodeConnection = {|
  __typename?: 'PayableNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?PayableNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type PayableNodeEdge = {|
  __typename?: 'PayableNodeEdge',
  node?: ?PayableNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const PayableOriginalPayableAmountCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type PayableOriginalPayableAmountCurrency = $Values<typeof PayableOriginalPayableAmountCurrencyValues>;

export const PayablePayableOriginValues = Object.freeze({
  BankFee: 'BANK_FEE',
  BankInterest: 'BANK_INTEREST',
  BatchPaymentWithBreakdown: 'BATCH_PAYMENT_WITH_BREAKDOWN',
  CashInternalTransfer: 'CASH_INTERNAL_TRANSFER',
  CashRegisterCashRevenue: 'CASH_REGISTER_CASH_REVENUE',
  Customer: 'CUSTOMER',
  EmployeeExpense: 'EMPLOYEE_EXPENSE',
  EmployeeOwnerTransfer: 'EMPLOYEE_OWNER_TRANSFER',
  EmployeePayroll: 'EMPLOYEE_PAYROLL',
  EmployeeTip: 'EMPLOYEE_TIP',
  EmploymentAgencyIntegrationSubsidies: 'EMPLOYMENT_AGENCY_INTEGRATION_SUBSIDIES',
  FinancialAuthority: 'FINANCIAL_AUTHORITY',
  InternalElectronicTransfer: 'INTERNAL_ELECTRONIC_TRANSFER',
  LoanOrDeposit: 'LOAN_OR_DEPOSIT',
  MerchantWrongChange: 'MERCHANT_WRONG_CHANGE',
  OwnerCapital: 'OWNER_CAPITAL',
  PaymentProviderSettlement: 'PAYMENT_PROVIDER_SETTLEMENT',
  SocialInsuranceStatement: 'SOCIAL_INSURANCE_STATEMENT',
  Supplier: 'SUPPLIER',
  TvAndRadioLicenseFees: 'TV_AND_RADIO_LICENSE_FEES',
  Unknown: 'UNKNOWN',
});

export type PayablePayableOrigin = $Values<typeof PayablePayableOriginValues>;

export type PaymentBookingsInput = {|
  scenario?: ?$ElementType<Scalars, 'String'>,
  amount?: ?$ElementType<Scalars, 'Float'>,
  vatRate?: ?$ElementType<Scalars, 'String'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
|};

export type PaymentExplanationProgress = {|
  ...Node,
  ...{|
    __typename?: 'PaymentExplanationProgress',
    id: $ElementType<Scalars, 'ID'>,
    total?: ?$ElementType<Scalars, 'Float'>,
    explained?: ?$ElementType<Scalars, 'Float'>,
    unexplained?: ?$ElementType<Scalars, 'Float'>,
  |},
|};

export type PaymentNode = {|
  ...Node,
  ...{|
    __typename?: 'PaymentNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    amount: $ElementType<Scalars, 'Float'>,
    paymentReference: $ElementType<Scalars, 'String'>,
    paymentDate: $ElementType<Scalars, 'Date'>,
    counterPartyIban?: ?$ElementType<Scalars, 'String'>,
    counterPartyName: $ElementType<Scalars, 'String'>,
    direction?: ?$ElementType<Scalars, 'String'>,
    paymentAccount: MerchantPaymentAccountNode,
    document?: ?DocumentNode,
    payableDatePeriod?: ?Array<$ElementType<Scalars, 'Date'>>,
    isBatched: $ElementType<Scalars, 'Boolean'>,
    payableOrigin: PaymentPayableOrigin,
    uploadStatus: PaymentUploadStatus,
    supplier?: ?SupplierNode,
    customer?: ?CustomerNode,
    paymentToTransactions?: ?PaymentToTransactionNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    cleanPaymentReference?: ?$ElementType<Scalars, 'String'>,
    paymentIdentificationOptions?: ?Array<?$ElementType<Scalars, 'String'>>,
    counterPartyType?: ?$ElementType<Scalars, 'String'>,
    isLinkedToPayable?: ?$ElementType<Scalars, 'Boolean'>,
    directedAmount?: ?$ElementType<Scalars, 'Float'>,
    processingState?: ?$ElementType<Scalars, 'String'>,
    isExplained?: ?$ElementType<Scalars, 'Boolean'>,
    isExplainedInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
    explanationDetail?: ?$ElementType<Scalars, 'String'>,
    paymentLabels?: ?Array<?$ElementType<Scalars, 'String'>>,
    replacementReceipt?: ?DocumentNode,
    canExplainByReplacementReceipt?: ?$ElementType<Scalars, 'Boolean'>,
    shouldMerchantIdentifyPayment?: ?$ElementType<Scalars, 'Boolean'>,
    hasMerchantIdentifiedPayment?: ?$ElementType<Scalars, 'Boolean'>,
    explanationProgressAmounts?: ?PaymentExplanationProgress,
    explanationProgressCounts?: ?PaymentExplanationProgress,
    verificationSource?: ?$ElementType<Scalars, 'String'>,
    bookingsSum?: ?$ElementType<Scalars, 'Float'>,
    isReconciled?: ?$ElementType<Scalars, 'Boolean'>,
    isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
    bookings?: ?BookkeepingNodeConnection,
    notes?: ?EntityNoteNodeConnection,
    isExportReady?: ?$ElementType<Scalars, 'Boolean'>,
    isHighlightedForReview?: ?$ElementType<Scalars, 'Boolean'>,
    eventPaymentAccount?: ?EventPaymentAccountNode,
  |},
|};

export type PaymentNodePaymentToTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type PaymentNodeBookingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  bookingDate_Year?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  export_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  export_Month?: ?$ElementType<Scalars, 'Date'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isReversed?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  relatedAccount?: ?$ElementType<Scalars, 'String'>,
  isContributingToTaxType?: ?$ElementType<Scalars, 'String'>,
|};

export type PaymentNodeNotesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  noteContext?: ?$ElementType<Scalars, 'String'>,
  noteContext_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type PaymentNodeConnection = {|
  __typename?: 'PaymentNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?PaymentNodeEdge>,
  totalCount?: ?$ElementType<Scalars, 'Int'>,
  totalAmount?: ?$ElementType<Scalars, 'Float'>,
  totalDirectedAmount?: ?$ElementType<Scalars, 'Float'>,
|};

export type PaymentNodeEdge = {|
  __typename?: 'PaymentNodeEdge',
  node?: ?PaymentNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const PaymentPayableOriginValues = Object.freeze({
  BankFee: 'BANK_FEE',
  BankInterest: 'BANK_INTEREST',
  BatchPaymentWithBreakdown: 'BATCH_PAYMENT_WITH_BREAKDOWN',
  CashInternalTransfer: 'CASH_INTERNAL_TRANSFER',
  CashRegisterCashRevenue: 'CASH_REGISTER_CASH_REVENUE',
  Customer: 'CUSTOMER',
  EmployeeExpense: 'EMPLOYEE_EXPENSE',
  EmployeeOwnerTransfer: 'EMPLOYEE_OWNER_TRANSFER',
  EmployeePayroll: 'EMPLOYEE_PAYROLL',
  EmployeeTip: 'EMPLOYEE_TIP',
  EmploymentAgencyIntegrationSubsidies: 'EMPLOYMENT_AGENCY_INTEGRATION_SUBSIDIES',
  FinancialAuthority: 'FINANCIAL_AUTHORITY',
  InternalElectronicTransfer: 'INTERNAL_ELECTRONIC_TRANSFER',
  LoanOrDeposit: 'LOAN_OR_DEPOSIT',
  MerchantWrongChange: 'MERCHANT_WRONG_CHANGE',
  OwnerCapital: 'OWNER_CAPITAL',
  PaymentProviderSettlement: 'PAYMENT_PROVIDER_SETTLEMENT',
  SocialInsuranceStatement: 'SOCIAL_INSURANCE_STATEMENT',
  Supplier: 'SUPPLIER',
  TvAndRadioLicenseFees: 'TV_AND_RADIO_LICENSE_FEES',
  Unknown: 'UNKNOWN',
});

export type PaymentPayableOrigin = $Values<typeof PaymentPayableOriginValues>;

export const PaymentToTransactionAmountCurrencyValues = Object.freeze({
  Xua: 'XUA',
  Afn: 'AFN',
  Dzd: 'DZD',
  Ars: 'ARS',
  Amd: 'AMD',
  Awg: 'AWG',
  Aud: 'AUD',
  Azn: 'AZN',
  Bsd: 'BSD',
  Bhd: 'BHD',
  Thb: 'THB',
  Pab: 'PAB',
  Bbd: 'BBD',
  Byn: 'BYN',
  Byr: 'BYR',
  Bzd: 'BZD',
  Bmd: 'BMD',
  Btn: 'BTN',
  Vef: 'VEF',
  Bob: 'BOB',
  Xba: 'XBA',
  Brl: 'BRL',
  Bnd: 'BND',
  Bgn: 'BGN',
  Bif: 'BIF',
  Xof: 'XOF',
  Xaf: 'XAF',
  Xpf: 'XPF',
  Cad: 'CAD',
  Cve: 'CVE',
  Kyd: 'KYD',
  Clp: 'CLP',
  Xts: 'XTS',
  Cop: 'COP',
  Kmf: 'KMF',
  Cdf: 'CDF',
  Bam: 'BAM',
  Nio: 'NIO',
  Crc: 'CRC',
  Hrk: 'HRK',
  Cup: 'CUP',
  Cuc: 'CUC',
  Czk: 'CZK',
  Gmd: 'GMD',
  Dkk: 'DKK',
  Mkd: 'MKD',
  Djf: 'DJF',
  Std: 'STD',
  Dop: 'DOP',
  Vnd: 'VND',
  Xcd: 'XCD',
  Egp: 'EGP',
  Svc: 'SVC',
  Etb: 'ETB',
  Eur: 'EUR',
  Xbb: 'XBB',
  Xbd: 'XBD',
  Xbc: 'XBC',
  Fkp: 'FKP',
  Fjd: 'FJD',
  Huf: 'HUF',
  Ghs: 'GHS',
  Gip: 'GIP',
  Xau: 'XAU',
  Xfo: 'XFO',
  Pyg: 'PYG',
  Gnf: 'GNF',
  Gyd: 'GYD',
  Htg: 'HTG',
  Hkd: 'HKD',
  Uah: 'UAH',
  Isk: 'ISK',
  Inr: 'INR',
  Irr: 'IRR',
  Iqd: 'IQD',
  Imp: 'IMP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Kes: 'KES',
  Pgk: 'PGK',
  Lak: 'LAK',
  Kwd: 'KWD',
  Aoa: 'AOA',
  Mmk: 'MMK',
  Gel: 'GEL',
  Lvl: 'LVL',
  Lbp: 'LBP',
  All: 'ALL',
  Hnl: 'HNL',
  Sll: 'SLL',
  Lsl: 'LSL',
  Lrd: 'LRD',
  Lyd: 'LYD',
  Szl: 'SZL',
  Ltl: 'LTL',
  Mga: 'MGA',
  Mwk: 'MWK',
  Myr: 'MYR',
  Tmm: 'TMM',
  Mur: 'MUR',
  Mzn: 'MZN',
  Mxv: 'MXV',
  Mxn: 'MXN',
  Mdl: 'MDL',
  Mad: 'MAD',
  Bov: 'BOV',
  Ngn: 'NGN',
  Ern: 'ERN',
  Nad: 'NAD',
  Npr: 'NPR',
  Ang: 'ANG',
  Ils: 'ILS',
  Ron: 'RON',
  Twd: 'TWD',
  Nzd: 'NZD',
  Kpw: 'KPW',
  Nok: 'NOK',
  Pen: 'PEN',
  Mro: 'MRO',
  Top: 'TOP',
  Pkr: 'PKR',
  Xpd: 'XPD',
  Mop: 'MOP',
  Php: 'PHP',
  Xpt: 'XPT',
  Gbp: 'GBP',
  Bwp: 'BWP',
  Qar: 'QAR',
  Gtq: 'GTQ',
  Zar: 'ZAR',
  Omr: 'OMR',
  Khr: 'KHR',
  Mvr: 'MVR',
  Idr: 'IDR',
  Rub: 'RUB',
  Rwf: 'RWF',
  Xdr: 'XDR',
  Shp: 'SHP',
  Sar: 'SAR',
  Rsd: 'RSD',
  Scr: 'SCR',
  Xag: 'XAG',
  Sgd: 'SGD',
  Sbd: 'SBD',
  Kgs: 'KGS',
  Sos: 'SOS',
  Tjs: 'TJS',
  Ssp: 'SSP',
  Lkr: 'LKR',
  Xsu: 'XSU',
  Sdg: 'SDG',
  Srd: 'SRD',
  Sek: 'SEK',
  Chf: 'CHF',
  Syp: 'SYP',
  Bdt: 'BDT',
  Wst: 'WST',
  Tzs: 'TZS',
  Kzt: 'KZT',
  Xxx: 'XXX',
  Ttd: 'TTD',
  Mnt: 'MNT',
  Tnd: 'TND',
  Try: 'TRY',
  Tmt: 'TMT',
  Tvd: 'TVD',
  Aed: 'AED',
  Xfu: 'XFU',
  Usd: 'USD',
  Usn: 'USN',
  Ugx: 'UGX',
  Clf: 'CLF',
  Cou: 'COU',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vuv: 'VUV',
  Che: 'CHE',
  Chw: 'CHW',
  Krw: 'KRW',
  Yer: 'YER',
  Jpy: 'JPY',
  Cny: 'CNY',
  Zmk: 'ZMK',
  Zmw: 'ZMW',
  Zwd: 'ZWD',
  Zwn: 'ZWN',
  Zwl: 'ZWL',
  Pln: 'PLN',
});

export type PaymentToTransactionAmountCurrency = $Values<typeof PaymentToTransactionAmountCurrencyValues>;

export type PaymentToTransactionNode = {|
  ...Node,
  ...{|
    __typename?: 'PaymentToTransactionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    payment: PaymentNode,
    businessTransaction: TransactionNode,
    amountCurrency: PaymentToTransactionAmountCurrency,
    amount: $ElementType<Scalars, 'Float'>,
    metadata?: ?$ElementType<Scalars, 'JSONString'>,
    savingAmount?: ?$ElementType<Scalars, 'Float'>,
    directedAmount?: ?$ElementType<Scalars, 'Float'>,
  |},
|};

export type PaymentToTransactionNodeConnection = {|
  __typename?: 'PaymentToTransactionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?PaymentToTransactionNodeEdge>,
|};

export type PaymentToTransactionNodeEdge = {|
  __typename?: 'PaymentToTransactionNodeEdge',
  node?: ?PaymentToTransactionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type PaymentUpdatedSubscription = {|
  __typename?: 'PaymentUpdatedSubscription',
  updatedPayment?: ?UpdatedPaymentNode,
|};

export const PaymentUploadStatusValues = Object.freeze({
  Booking: 'BOOKING',
  CreatingEntities: 'CREATING_ENTITIES',
  Done: 'DONE',
  Extracting: 'EXTRACTING',
  Failed: 'FAILED',
  GettingOcr: 'GETTING_OCR',
  Linking: 'LINKING',
  PaymentCreated: 'PAYMENT_CREATED',
  ProcessingExtraction: 'PROCESSING_EXTRACTION',
});

export type PaymentUploadStatus = $Values<typeof PaymentUploadStatusValues>;

export type Query = {|
  __typename?: 'Query',
  taskAction?: ?TaskActionNode,
  taskActions?: ?TaskActionNodeConnection,
  bookkeeping?: ?BookkeepingNode,
  bookkeepings?: ?BookkeepingNodeConnection,
  bookingScenario?: ?BookingScenarioNode,
  recommendedBookingScenarios?: ?Array<?BookingScenarioNode>,
  bookingScenarios?: ?Array<?BookingScenarioNode>,
  monthEndBalances?: ?Array<?MonthEndBalancesNode>,
  bookingRecommendations?: ?Array<?BookingRecommendationNode>,
  bookkeepingExports?: ?Array<?BookkeepingExportNode>,
  bookingRuleSchema?: ?BookingRuleSchemaNode,
  node?: ?Node,
  address?: ?AddressNode,
  addresses?: ?AddressNodeConnection,
  transaction?: ?TransactionNode,
  transactions?: ?TransactionNodeConnection,
  document?: ?DocumentNode,
  documents?: ?DocumentNodeConnection,
  business?: ?BusinessNode,
  businesses?: ?BusinessNodeConnection,
  payment?: ?PaymentNode,
  payments?: ?PaymentNodeConnection,
  payable?: ?PayableNode,
  payables?: ?PayableNodeConnection,
  issue?: ?IssueNode,
  issues?: ?IssueNodeConnection,
  businessEmployee?: ?BusinessEmployeeNode,
  businessEmployees?: ?BusinessEmployeeNodeConnection,
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
  merchantPaymentAccounts?: ?MerchantPaymentAccountNodeConnection,
  paymentToTransaction?: ?PaymentToTransactionNode,
  paymentToTransactions?: ?PaymentToTransactionNodeConnection,
  appUser?: ?AppUserNode,
  appUsers?: ?AppUserNodeConnection,
  jsonScheme?: ?JsonSchemeNode,
  recordTypes?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
  classificationRecordTypeComboBoxOptions?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
  allAvailableEventTypes?: ?Array<?EventType>,
  internalMissingPayableOrigins?: ?Array<?$ElementType<Scalars, 'String'>>,
  mecEvent?: ?MecNode,
  mecEvents?: ?MecNodeConnection,
  dummy?: ?$ElementType<Scalars, 'String'>,
  documentFieldSources?: ?$ElementType<Scalars, 'JSONString'>,
  processingStatus?: ?$ElementType<Scalars, 'String'>,
  documentExtractedFields?: ?$ElementType<Scalars, 'JSONString'>,
  onlineExtractions?: ?$ElementType<Scalars, 'JSONString'>,
|};

export type QueryTaskActionArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryBookkeepingArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryBookkeepingsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  bookingDate_Year?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Year_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Month_Gte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  bookingDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  account?: ?$ElementType<Scalars, 'Int'>,
  account_In?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount?: ?$ElementType<Scalars, 'Int'>,
  offsetingAccount_In?: ?$ElementType<Scalars, 'Int'>,
  export_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  export_Month?: ?$ElementType<Scalars, 'Date'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  isReversed?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  relatedAccount?: ?$ElementType<Scalars, 'String'>,
  isContributingToTaxType?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBookingScenarioArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryRecommendedBookingScenariosArgs = {|
  recordId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBookingScenariosArgs = {|
  recordId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryMonthEndBalancesArgs = {|
  month?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBookingRecommendationsArgs = {|
  recordId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBookkeepingExportsArgs = {|
  month?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryNodeArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryAddressArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryAddressesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryTransactionArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  minPayablesAmount?: ?$ElementType<Scalars, 'String'>,
  maxPayablesAmount?: ?$ElementType<Scalars, 'String'>,
  minPaymentsAmount?: ?$ElementType<Scalars, 'String'>,
  maxPaymentsAmount?: ?$ElementType<Scalars, 'String'>,
  counterParty?: ?$ElementType<Scalars, 'String'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  reference?: ?$ElementType<Scalars, 'String'>,
  matchToRecord?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryDocumentArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  documentType_In?: ?$ElementType<Scalars, 'String'>,
  documentType_Nin?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
  businessId_In?: ?$ElementType<Scalars, 'ID'>,
  payables_Direction?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  excludeForMerchant?: ?$ElementType<Scalars, 'Boolean'>,
  recordOccurrenceDateFrom?: ?$ElementType<Scalars, 'String'>,
  recordOccurrenceDateTo?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'String'>,
  hasUnbookableZreports?: ?$ElementType<Scalars, 'Boolean'>,
  flagForLater?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryBusinessArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryBusinessesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  legalName?: ?$ElementType<Scalars, 'String'>,
  legalName_Iexact?: ?$ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  displayName_Iexact?: ?$ElementType<Scalars, 'String'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
  offboardingDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  offboardingDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  isTestOrDemo?: ?$ElementType<Scalars, 'Boolean'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  nameForEmailBox?: ?$ElementType<Scalars, 'String'>,
  adpClientId?: ?$ElementType<Scalars, 'Float'>,
  onlyNed?: ?$ElementType<Scalars, 'Boolean'>,
  onlyFactory?: ?$ElementType<Scalars, 'Boolean'>,
  taxAdviser_Id_In?: ?$ElementType<Scalars, 'String'>,
  taxAdviser_Id?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryPaymentArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryPaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  direction_In?: ?$ElementType<Scalars, 'String'>,
  paymentDate?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Range?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Lte?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gt?: ?$ElementType<Scalars, 'Date'>,
  paymentDate_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  isLinkable?: ?$ElementType<Scalars, 'Boolean'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  payableOrigin?: ?$ElementType<Scalars, 'String'>,
  payableOrigin_In?: ?$ElementType<Scalars, 'String'>,
  paymentsPerMonth?: ?$ElementType<Scalars, 'Date'>,
  search?: ?$ElementType<Scalars, 'String'>,
  containsInCounterParty?: ?$ElementType<Scalars, 'String'>,
  containsInPaymentReference?: ?Array<?$ElementType<Scalars, 'String'>>,
  excludeBookingCompletePayments?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type QueryPayableArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryPayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  direction?: ?$ElementType<Scalars, 'String'>,
  dateOccurred_Gte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred_Lte?: ?$ElementType<Scalars, 'Date'>,
  dateOccurred?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isBooked?: ?$ElementType<Scalars, 'Boolean'>,
  isInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  isLinked?: ?$ElementType<Scalars, 'Boolean'>,
  isInProcessing?: ?$ElementType<Scalars, 'Boolean'>,
  excludeAccountZeroBookings?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type QueryIssueArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryBusinessEmployeeArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryBusinessEmployeesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  email?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export type QueryMerchantPaymentAccountArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryMerchantPaymentAccountsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  accountType?: ?$ElementType<Scalars, 'String'>,
  accountType_In?: ?$ElementType<Scalars, 'String'>,
  business?: ?$ElementType<Scalars, 'ID'>,
  predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  isOnlyInRelevancePeriod?: ?$ElementType<Scalars, 'Boolean'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type QueryPaymentToTransactionArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryPaymentToTransactionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryAppUserArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryAppUsersArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type QueryJsonSchemeArgs = {|
  name?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryRecordTypesArgs = {|
  relevantForLinking?: ?$ElementType<Scalars, 'Boolean'>,
  relevantForBooking?: ?$ElementType<Scalars, 'Boolean'>,
  relevantForRecordTypeField?: ?$ElementType<Scalars, 'Boolean'>,
  schemeCategory?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryMecEventArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryMecEventsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  month?: ?$ElementType<Scalars, 'Date'>,
  month_In?: ?$ElementType<Scalars, 'Date'>,
  month_Lt?: ?$ElementType<Scalars, 'Date'>,
  month_Lte?: ?$ElementType<Scalars, 'Date'>,
  month_Gt?: ?$ElementType<Scalars, 'Date'>,
  month_Gte?: ?$ElementType<Scalars, 'Date'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryDocumentFieldSourcesArgs = {|
  documentId: $ElementType<Scalars, 'String'>,
  businessId: $ElementType<Scalars, 'String'>,
|};

export type QueryProcessingStatusArgs = {|
  documentId: $ElementType<Scalars, 'String'>,
  businessId: $ElementType<Scalars, 'String'>,
|};

export type QueryDocumentExtractedFieldsArgs = {|
  documentId: $ElementType<Scalars, 'String'>,
  businessId: $ElementType<Scalars, 'String'>,
|};

export type QueryOnlineExtractionsArgs = {|
  businessId: $ElementType<Scalars, 'String'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  documentTypesToExtract?: ?Array<?$ElementType<Scalars, 'String'>>,
  classifyDocument?: ?$ElementType<Scalars, 'Boolean'>,
  extractFields?: ?$ElementType<Scalars, 'Boolean'>,
  returnOcrText?: ?$ElementType<Scalars, 'Boolean'>,
  userSelectedDocumentType?: ?$ElementType<Scalars, 'String'>,
|};

export type SkipMecInput = {|
  mecId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SkipMecPayload = {|
  __typename?: 'SkipMECPayload',
  skippedMec?: ?MecNode,
  nextMec?: ?MecNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type Subscription = {|
  __typename?: 'Subscription',
  documentUpdatedSubscription?: ?DocumentUpdatedSubscription,
  paymentUpdatedSubscription?: ?PaymentUpdatedSubscription,
  bookingRuleUpdatedSubscription?: ?BookingRuleUpdatedSubscription,
|};

export type SubscriptionDocumentUpdatedSubscriptionArgs = {|
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export type SubscriptionPaymentUpdatedSubscriptionArgs = {|
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export type SubscriptionBookingRuleUpdatedSubscriptionArgs = {|
  businessId?: ?$ElementType<Scalars, 'ID'>,
|};

export const SubscriptionPolingTypeEnumValues = Object.freeze({
  BookingRule: 'booking_rule',
  Document: 'document',
  Payment: 'payment',
});

export type SubscriptionPolingTypeEnum = $Values<typeof SubscriptionPolingTypeEnumValues>;

export type SupplierNode = {|
  ...Node,
  ...{|
    __typename?: 'SupplierNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    legalName: $ElementType<Scalars, 'String'>,
    isAutoGeneratedLegalName: $ElementType<Scalars, 'Boolean'>,
    isLegalNameEditedByTheUser: $ElementType<Scalars, 'Boolean'>,
    vatNumbers: Array<$ElementType<Scalars, 'String'>>,
    taxNumbers: Array<$ElementType<Scalars, 'String'>>,
    ibans: Array<$ElementType<Scalars, 'String'>>,
    account?: ?$ElementType<Scalars, 'Int'>,
    documents: DocumentNodeConnection,
    payments: PaymentNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    accountCaption?: ?$ElementType<Scalars, 'String'>,
    numberOfRules?: ?$ElementType<Scalars, 'Int'>,
  |},
|};

export type SupplierNodeDocumentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  business?: ?$ElementType<Scalars, 'ID'>,
|};

export type SupplierNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type SupplierNodeConnection = {|
  __typename?: 'SupplierNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?SupplierNodeEdge>,
|};

export type SupplierNodeEdge = {|
  __typename?: 'SupplierNodeEdge',
  node?: ?SupplierNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaskActionNode = {|
  ...Node,
  ...{|
    __typename?: 'TaskActionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    actionName?: ?$ElementType<Scalars, 'String'>,
    metadata: $ElementType<Scalars, 'JSONString'>,
    sourceEntityId?: ?$ElementType<Scalars, 'UUID'>,
    ignoreWarning: $ElementType<Scalars, 'Boolean'>,
    executionDurationSeconds?: ?$ElementType<Scalars, 'Float'>,
    business: BusinessNode,
    issue?: ?IssueNode,
    executingUsername?: ?$ElementType<Scalars, 'String'>,
    comment?: ?$ElementType<Scalars, 'String'>,
  |},
|};

export type TaskActionNodeConnection = {|
  __typename?: 'TaskActionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?TaskActionNodeEdge>,
|};

export type TaskActionNodeEdge = {|
  __typename?: 'TaskActionNodeEdge',
  node?: ?TaskActionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaxAdviserNode = {|
  ...Node,
  ...{|
    __typename?: 'TaxAdviserNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    legalName: $ElementType<Scalars, 'String'>,
    address?: ?AddressNode,
    contactPhoneNumber: $ElementType<Scalars, 'String'>,
    contactEmail: $ElementType<Scalars, 'String'>,
    datevName?: ?$ElementType<Scalars, 'String'>,
    datevNumber?: ?$ElementType<Scalars, 'Int'>,
    businessGroups: TaxAdvisorBusinessGroupNodeConnection,
    businesses: BusinessNodeConnection,
    bookingaccountcaptionSet: BookingAccountCaptionNodeConnection,
  |},
|};

export type TaxAdviserNodeBusinessGroupsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'UUID'>,
  taxAdvisorId?: ?$ElementType<Scalars, 'ID'>,
|};

export type TaxAdviserNodeBusinessesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaxAdviserNodeBookingaccountcaptionSetArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaxAdviserNodeConnection = {|
  __typename?: 'TaxAdviserNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?TaxAdviserNodeEdge>,
|};

export type TaxAdviserNodeEdge = {|
  __typename?: 'TaxAdviserNodeEdge',
  node?: ?TaxAdviserNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export const TaxAdvisorBusinessGroupGroupTypeValues = Object.freeze({
  AllBusinesses: 'ALL_BUSINESSES',
  BusinessIndustry: 'BUSINESS_INDUSTRY',
  BusinessList: 'BUSINESS_LIST',
});

export type TaxAdvisorBusinessGroupGroupType = $Values<typeof TaxAdvisorBusinessGroupGroupTypeValues>;

export type TaxAdvisorBusinessGroupNode = {|
  ...Node,
  ...{|
    __typename?: 'TaxAdvisorBusinessGroupNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    taxAdvisor: TaxAdviserNode,
    name?: ?$ElementType<Scalars, 'String'>,
    groupType: TaxAdvisorBusinessGroupGroupType,
    businesses: BusinessNodeConnection,
    bookingRules: BookingRuleNodeConnection,
  |},
|};

export type TaxAdvisorBusinessGroupNodeBusinessesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaxAdvisorBusinessGroupNodeBookingRulesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaxAdvisorBusinessGroupNodeConnection = {|
  __typename?: 'TaxAdvisorBusinessGroupNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?TaxAdvisorBusinessGroupNodeEdge>,
|};

export type TaxAdvisorBusinessGroupNodeEdge = {|
  __typename?: 'TaxAdvisorBusinessGroupNodeEdge',
  node?: ?TaxAdvisorBusinessGroupNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TransactionNode = {|
  ...Node,
  ...{|
    __typename?: 'TransactionNode',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessNode,
    payments: PaymentNodeConnection,
    paymentsToTransaction: PaymentToTransactionNodeConnection,
    allPayables: PayableNodeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
    issues?: ?IssueNodeConnection,
    taskActions?: ?TaskActionNodeConnection,
    paymentStatus?: ?$ElementType<Scalars, 'String'>,
    hasNoOpenReconIssues?: ?$ElementType<Scalars, 'Boolean'>,
    hasBookingsOfTipOrDiscountSolver?: ?$ElementType<Scalars, 'Boolean'>,
    isAllRecordsInTransactionVerified?: ?$ElementType<Scalars, 'Boolean'>,
    notes?: ?EntityNoteNodeConnection,
    payables?: ?PayableNodeConnection,
    payableOrigin?: ?$ElementType<Scalars, 'String'>,
    replacementReceiptScheme?: ?$ElementType<Scalars, 'JSONString'>,
    mainPayable?: ?PayableNode,
    privateExpenseOwner?: ?BusinessEmployeeNode,
    explanationDetail?: ?$ElementType<Scalars, 'String'>,
    explanationLabels?: ?Array<?$ElementType<Scalars, 'String'>>,
    isExplained?: ?$ElementType<Scalars, 'Boolean'>,
    replacementReceipt?: ?DocumentNode,
    canExplainByReplacementReceipt?: ?$ElementType<Scalars, 'Boolean'>,
  |},
|};

export type TransactionNodePaymentsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodePaymentsToTransactionArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodeAllPayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodeIssuesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  issueType?: ?$ElementType<Scalars, 'String'>,
  issueType_In?: ?$ElementType<Scalars, 'String'>,
  isOpen?: ?$ElementType<Scalars, 'Boolean'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt?: ?$ElementType<Scalars, 'DateTime'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type TransactionNodeTaskActionsArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  actionName?: ?$ElementType<Scalars, 'String'>,
  actionName_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  metadata?: ?$ElementType<Scalars, 'String'>,
|};

export type TransactionNodeNotesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  noteContext?: ?$ElementType<Scalars, 'String'>,
  noteContext_In?: ?$ElementType<Scalars, 'String'>,
  id_In?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
|};

export type TransactionNodePayablesArgs = {|
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TransactionNodeConnection = {|
  __typename?: 'TransactionNodeConnection',
  pageInfo: PageInfo,
  edges: Array<?TransactionNodeEdge>,
|};

export type TransactionNodeEdge = {|
  __typename?: 'TransactionNodeEdge',
  node?: ?TransactionNode,
  cursor: $ElementType<Scalars, 'String'>,
|};

export type UpdatedBookingRuleNode = {|
  __typename?: 'UpdatedBookingRuleNode',
  business?: ?BusinessNode,
  bookingRule?: ?BookingRuleNode,
  deletedBookingRuleId?: ?$ElementType<Scalars, 'ID'>,
|};

export type UpdatedDocumentNode = {|
  __typename?: 'UpdatedDocumentNode',
  business?: ?BusinessNode,
  document?: ?DocumentNode,
|};

export type UpdatedEntitiesNode = {|
  __typename?: 'UpdatedEntitiesNode',
  id: $ElementType<Scalars, 'ID'>,
  lastCursor?: ?$ElementType<Scalars, 'String'>,
  entities?: ?Array<?UpdatedEntityUnion>,
|};

export type UpdatedEntityUnion = UpdatedPaymentNode | UpdatedDocumentNode | UpdatedBookingRuleNode;

export type UpdateDocumentInput = {|
  businessId: $ElementType<Scalars, 'String'>,
  documentId: $ElementType<Scalars, 'String'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  extractionData?: ?$ElementType<Scalars, 'JSONString'>,
  bookingsInfoData?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateDocumentPayload = {|
  __typename?: 'UpdateDocumentPayload',
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatedPaymentNode = {|
  __typename?: 'UpdatedPaymentNode',
  business?: ?BusinessNode,
  payment?: ?PaymentNode,
  deletedPaymentId?: ?$ElementType<Scalars, 'ID'>,
  eventPaymentAccount?: ?EventPaymentAccountNode,
|};

export type UpdateLabelerDocumentDataInput = {|
  documentId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  documentType?: ?$ElementType<Scalars, 'String'>,
  data?: ?$ElementType<Scalars, 'JSONString'>,
  isLabelerFinished?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateLabelerDocumentDataPayload = {|
  __typename?: 'UpdateLabelerDocumentDataPayload',
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateMerchantPaymentAccountInput = {|
  merchantPaymentAccountId: $ElementType<Scalars, 'String'>,
  displayName?: ?$ElementType<Scalars, 'String'>,
  bankAccount?: ?$ElementType<Scalars, 'String'>,
  predefinedBookingAccount?: ?$ElementType<Scalars, 'Int'>,
  activeSince?: ?$ElementType<Scalars, 'Date'>,
  activeUntil?: ?$ElementType<Scalars, 'Date'>,
  isCashRegisterPointOfSale?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateMerchantPaymentAccountPayload = {|
  __typename?: 'UpdateMerchantPaymentAccountPayload',
  merchantPaymentAccount?: ?MerchantPaymentAccountNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentBookingsInput = {|
  paymentId?: ?$ElementType<Scalars, 'String'>,
  bookings?: ?Array<?PaymentBookingsInput>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentBookingsPayload = {|
  __typename?: 'UpdatePaymentBookingsPayload',
  payment?: ?PaymentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentDetailsInput = {|
  paymentId: $ElementType<Scalars, 'String'>,
  paymentDate: $ElementType<Scalars, 'Date'>,
  amount: $ElementType<Scalars, 'Float'>,
  paymentReference: $ElementType<Scalars, 'String'>,
  counterPartyName?: ?$ElementType<Scalars, 'String'>,
  counterPartyIban?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentDetailsPayload = {|
  __typename?: 'UpdatePaymentDetailsPayload',
  eventPaymentAccount?: ?EventPaymentAccountNode,
  payment?: ?PaymentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
  errorType?: ?$ElementType<Scalars, 'String'>,
  errorMessage?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdatePaymentDetailsPayloadPaymentArgs = {|
  extra?: ?$ElementType<Scalars, 'String'>,
|};

export type UploadDocumentInput = {|
  documentId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  fileName?: ?$ElementType<Scalars, 'String'>,
  documentContent?: ?$ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UploadDocumentPayload = {|
  __typename?: 'UploadDocumentPayload',
  document?: ?DocumentNode,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UploadNewDocuments = {|
  __typename?: 'UploadNewDocuments',
  business?: ?BusinessNode,
|};

export type VerifyDoubleBookingInput = {|
  bookingId?: ?$ElementType<Scalars, 'String'>,
  businessId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type VerifyDoubleBookingPayload = {|
  __typename?: 'VerifyDoubleBookingPayload',
  updatedBookings?: ?Array<?BookkeepingNode>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

import { useEffect, useRef } from 'react';
import _ from 'lodash';
import { trimEdgesAndNodes } from './relay';
import { PaymentAccountTypeEnum } from './enums';

export const isDev = () => process.env.REACT_APP_IS_DEV === 'true';

export function createMessageHandler(source, callbackFunction) {
  return (e) => {
    if (e.origin !== window.location.origin) {
      return;
    }
    if (e.data.source === source) {
      callbackFunction();
    }
  };
}

export function useCreateMessageHandler(source, callbackFunction) {
  useEffect(() => {
    const handleMessage = createMessageHandler(source, callbackFunction);
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });
}

export const toCamel = (str) => _.camelCase(str);

const isArray = (a) => Array.isArray(a);
const isObject = (o) => o === Object(o) && !isArray(o) && typeof o !== 'function';

export const toSnake = (str) => _.snakeCase(str);

export const transformObjectKeys = (obj, transformFunc) => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[transformFunc(k)] = transformObjectKeys(obj[k]);
    });

    return n;
  }
  if (isArray(obj)) {
    return obj.map((i) => transformObjectKeys(i));
  }

  return obj;
};
export const doesObjectHaveUnsetProperties = (obj) => {
  return _.findIndex(Object.values(obj), (val) => !val && val !== 0) > -1;
};

export const doesObjectHaveSetProperties = (obj) => {
  return _.findIndex(Object.values(obj), (val) => !!val || val === 0) > -1;
};

export const updateItemInArray = (identifier, newProps, array, identifierKey = 'id') => {
  return _.map(array, (item) => (item[identifierKey] === identifier ? { ...item, ...newProps } : item));
};

export const orderBusinessList = (businesses, isTan = false) =>
  _.orderBy(
    businesses,
    [
      (business) => (isTan ? business.legalName : business.displayName).toLowerCase(),
      (business) => business.contactFullName,
      (business) => business.accountingOnboardingDate,
    ],
    ['asc', 'asc', 'asc'],
  );

export const addIndexToArrayItems = (array) => {
  return _.map(array, (item, index) => ({ ...item, index }));
};

export const businessHasCashRegister = (business) => {
  const paymentAccounts = trimEdgesAndNodes(business?.paymentAccounts);

  return (
    _.size(
      _.filter(
        paymentAccounts,
        (paymentAccount) => paymentAccount.accountType === PaymentAccountTypeEnum.cash_register,
      ),
    ) > 0
  );
};

export const areObjectsIdentical = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const safeJsonParse = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

export const getContactNameFromSalesforceData = (salesforceDataJson) => {
  if (!salesforceDataJson) {
    return null;
  }

  const data = safeJsonParse(salesforceDataJson);

  if (!data) {
    return null;
  }

  const first = data['First ZG Contact First Name'] || '';
  const last = data['First ZG Contact Last Name'] || '';

  return `${first} ${last}`.trim();
};

export const useIsMounted = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export const removeDataPrefix = (content) => {
  return content.replace(/^data:\w+\/[\w.-]+;base64,/, '');
};

export const useOnDefaultChanged = (defaultValues, reset) => {
  const previousValueRef = useRef(defaultValues);
  if (!_.isEqual(defaultValues, previousValueRef.current)) {
    reset(defaultValues);
  }
  previousValueRef.current = defaultValues;
};

// @flow
import Cookies from 'universal-cookie';
import type TrackerInterface from './TrackerInterface';
import WaitableValue from '../WaitableValue';

const SESSION_TIME_IN_SECONDS = 30 * 60;

class TrackerWrapper implements TrackerInterface {
  trackerWaitable = new WaitableValue();

  isBusinessInitializedWaitable = new WaitableValue();

  currentPage = null;

  cookies = new Cookies();

  setTracker(tracker) {
    this.trackerWaitable.set(tracker);
  }

  trackSessions() {
    if (this.cookies.get('tracker_session') === undefined) {
      this.cookies.set('tracker_session', '', { path: '/', maxAge: SESSION_TIME_IN_SECONDS });
      return this.trackEvent('app_session');
    }
    return null;
  }

  async setUserInfo(userId, userEmail) {
    const tracker = await this.trackerWaitable.get();
    return tracker.setUserInfo(userId, userEmail);
  }

  async setBusiness(businessId, businessName, businessLegalName, IsTan, tanIds, tanLegalName) {
    const tracker = await this.trackerWaitable.get();
    await tracker.setBusiness(businessId, businessName, businessLegalName, IsTan, tanIds, tanLegalName);
    this.isBusinessInitializedWaitable.set(true);
  }

  async trackEvent(event, properties = {}) {
    const tracker = await this.trackerWaitable.get();
    await this.isBusinessInitializedWaitable.wait();
    await this.trackSessions();
    return tracker.trackEvent(event, properties);
  }

  // TrackEvent without waiting for init (and without session tracking).
  // Example use case: when logging out, we want to wait on the tracking event, but if tracker wasn't initialized yet,
  //  just skip the tracking.
  // Returns true if event was tracked. false if tracker wasn't initialized yet.
  async tryTrackEvent(event, properties = {}) {
    const tracker = this.trackerWaitable.value;
    if (tracker) {
      await tracker.trackEvent(event, properties);
      return true;
    }
    return false;
  }

  async trackPageChange(pageName) {
    let result = null;
    if (this.currentPage !== pageName) {
      this.currentPage = pageName;
      const tracker = await this.trackerWaitable.get();
      this.isBusinessInitializedWaitable.wait();
      await this.trackSessions();
      result = tracker.trackPageChange(pageName);
    }
    return result;
  }

  logout() {
    const tracker = this.trackerWaitable.value;
    if (tracker) {
      tracker.logout();
    }
  }
}

const mixpanel = new TrackerWrapper();
export default mixpanel;

/* eslint-disable no-underscore-dangle */
class RelayPipeline {
  store;

  get fetchCallback() {
    return this._fetchCallback;
  }

  set fetchCallback(value) {
    this._fetchCallback = value;
  }

  get environment() {
    return this._environment;
  }

  set environment(value) {
    this._environment = value;
    this.store = value.getStore().getSource();
  }

  findObjectById = (id) => {
    if (!this.environment) return null;

    return this.store.get(id);
  };

  onFetch(environment) {
    if (this._fetchCallback) {
      this._fetchCallback(environment);
    }
  }
}

const pipeline = new RelayPipeline();

export default pipeline;

import _ from 'lodash';
import { UserTypeEnum } from './enums';
// eslint-disable-next-line import/no-cycle
import { getAuth0CustomerSuccess } from '../auth0/react-auth0-wrapper';
import { isDev } from './utils';
import { BusinessBookingIndustryValues } from '../schema/generated/graphql';

// eslint-disable-next-line no-console,import/prefer-default-export
export const isGastronomy = (bookingIndustry) => bookingIndustry === BusinessBookingIndustryValues.Gastronomy;

export const isTanUser = (userType) => userType === UserTypeEnum.tan || userType === UserTypeEnum.ops;

export const getIsExternalUser = async () => {
  const customerSuccess = await getAuth0CustomerSuccess();
  return !isDev() && _.isEmpty(customerSuccess);
};

export const getEboxMail = (isNed, isTan, nameForEmailBox) => {
  return isNed && !isTan ? `${nameForEmailBox}@${process.env.REACT_APP_EBOX_MAIL}` : null;
};

// @flow
import type AnalyticsInterface from './AnalyticsInterface';
import WaitableValue from '../WaitableValue';

export class AnalyticsWrapper implements AnalyticsInterface {
  analyticsWaitable = new WaitableValue();

  setAnalytics = (newAnalytics) => {
    this.analyticsWaitable.set(newAnalytics);
  };

  setUserId = async (userId) => {
    const analytics = await this.analyticsWaitable.get();
    analytics.setUserId(userId);
  };

  trackTiming = async (category, durationMs, variable, label) => {
    const analytics = await this.analyticsWaitable.get();
    analytics.trackTiming(category, durationMs, variable, label);
  };

  trackEvent = async (category, action, label, value) => {
    const analytics = await this.analyticsWaitable.get();
    analytics.trackEvent(category, action, label, value);
  };

  trackPageChange = async (pageName) => {
    const analytics = await this.analyticsWaitable.get();
    analytics.trackPageChange(pageName);
  };
}

const analytics = new AnalyticsWrapper();
export default analytics;

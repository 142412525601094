import ResetEvent from './ResetEvent';

class WaitableValue {
  value = null;

  resetEvent = new ResetEvent();

  // Waits for value to be non-null, and returns the value.
  get = async () => {
    await this.wait();
    return this.value;
  };

  // Set the value
  set = (value) => {
    this.value = value;
    if (value != null) {
      this.resetEvent.set();
    }
  };

  // Wait for a non-null value
  wait = async () => {
    if (this.value == null) {
      await this.resetEvent.wait();
    }
  };
}

export default WaitableValue;

// @flow
const operationNameToRelayResponse = {};

export const addRelayQueryMock = (operationName, relayResponse) => {
  operationNameToRelayResponse[operationName] = relayResponse;
};

export const mockQueryMiddleware = () => {
  return (next) => async (req) => {
    const mockResponse = operationNameToRelayResponse[req.operation.name];
    if (mockResponse) {
      return mockResponse;
    }
    return next(req);
  };
};

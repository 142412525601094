// @flow

// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import { Middleware } from 'react-relay-network-modern';
import analytics from '../utils/analytics';

export type PerfMiddlewareOpts = {
  logger?: Function,
  shouldLog?: boolean,
  publishMinThresholdMs?: number,
  publishMaxThresholdMs?: number,
  shouldPublish?: boolean,
  publishCategory?: string,
  publishEventName?: string,
  publishLabel?: string,
};

const defaultOpts: PerfMiddlewareOpts = {
  // eslint-disable-next-line no-console
  logger: console.log.bind(console, '[RELAY-NETWORK]'),
  shouldLog: true,
  publishMinThresholdMs: 0,
  publishMaxThresholdMs: 120000,
  shouldPublish: false,
  publishCategory: 'relay-perf',
  publishEventName: 'network-middleware',
  publishLabel: '',
};

function perfMiddleware(opts?: PerfMiddlewareOpts): Middleware {
  const optsFinal = { ...defaultOpts, ...opts };

  return (next) => (req) => {
    const start = new Date().getTime();

    return next(req).then((res) => {
      const end = new Date().getTime();
      const durationMs = end - start;
      const reqId = req.getID();

      if (optsFinal.shouldLog) {
        optsFinal.logger(`[${durationMs}ms] ${reqId}`, req, res);
      }

      if (
        optsFinal.shouldPublish &&
        durationMs >= optsFinal.publishMinThresholdMs &&
        durationMs <= optsFinal.publishMaxThresholdMs
      ) {
        const label = optsFinal.publishLabel || reqId;
        analytics.trackTiming(optsFinal.publishCategory, durationMs, optsFinal.publishEventName, label);
      }

      return res;
    });
  };
}

export default perfMiddleware;

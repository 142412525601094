import { createRelayResponse } from '../utils/relay';

let shouldBlockMutations = false;
let onMutationBlocked = () => {};

export const enableMutationsBlockingMiddleware = (callback = () => {}) => {
  shouldBlockMutations = true;
  onMutationBlocked = callback;
};

export const disableMutationsBlockingMiddleware = () => {
  shouldBlockMutations = false;
  onMutationBlocked = () => {};
};

function blockMerchantMutationDynamicMiddleware() {
  return (next) => async (req) => {
    if (!req.isMutation()) {
      return next(req);
    }

    if (shouldBlockMutations) {
      onMutationBlocked();
    } else {
      return next(req);
    }

    const errorMsg = 'Mutations are not allowed';
    return createRelayResponse([errorMsg], null);
  };
}

export default blockMerchantMutationDynamicMiddleware;

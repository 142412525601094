export const CustomerSuccessEnum = {
  read_only_agent: 'true',
  full_access_agent: 'full',
};

export const UserTypeEnum = {
  ops: 'Ops',
  tan: 'Tan',
  user: 'User',
};

export const PaymentAccountTypeEnum = {
  bank_account: 'bank_account',
  credit_card: 'credit_card',
  payment_provider: 'payment_provider',
  cash_register: 'cash_register',
};

export const MomentFormatEnum = {
  day_month_year_underscore: 'DD_MM_YYYY',
  day_month_year_dots: 'DD.MM.YYYY',
  year_month_underscore: 'YYYY_MM',
};

export const DirectionEnum = {
  incoming: 'incoming',
  outgoing: 'outgoing',
};
export const paymentDirections = {
  incoming: 'incoming',
  outgoing: 'outgoing',
};

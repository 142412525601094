import { SubscriptionClient } from 'subscriptions-transport-ws';
import EventEmitter from 'eventemitter3';

const DISCONNECTED_TIMEOUT_FOR_FAILURE = 5000;

export class SubscriptionClientWrapper extends SubscriptionClient {
  constructor(url, options, webSocketImpl, webSocketProtocols) {
    super(url, options, webSocketImpl, webSocketProtocols);

    this.connectedEvent = new EventEmitter();
    this.connected = null;
    this.timeout = null;

    this.onDisconnected(this.onDisconnectedCallback.bind(this));
    this.onReconnected(this.onConnectedCallback.bind(this));
    this.onConnected(this.onConnectedCallback.bind(this));
  }

  getIsConnected() {
    return this.connected;
  }

  onConnectedCallback() {
    if (this.connected !== true) {
      this.connected = true;
      this.connectedEvent.emit('changed', this.connected);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  onDisconnectedCallback() {
    if (!this.timeout && this.connected !== false) {
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.connected = false;
        this.connectedEvent.emit('changed', this.connected);
      }, DISCONNECTED_TIMEOUT_FOR_FAILURE);
    }
  }
}

import React, { Suspense } from 'react';
import { hydrate, render } from 'react-dom';
import Bugsnag from '@bugsnag/js';

import i18n from './i18n';
import { Auth0Provider } from './auth0/react-auth0-wrapper';
import config from './auth0/auth_config.json';
import { GlobalStateProvider } from './state';
import ExperimentRootComponent from './experiments/ExperimentRoot';
import { bugsnagOptions } from './utils/bugsnag';
import mixpanel from './utils/mixpanel';
import LogTracker from './utils/mixpanel/LogTracker';
import MixpanelTracker from './utils/mixpanel/MixpanelTracker';
import analytics from './utils/analytics';
import LogAnalytics from './utils/analytics/LogAnalytics';
import GoogleAnalytics from './utils/analytics/GoogleAnalytics';
import { getIsExternalUser } from './utils/user';
import { LoadingPage } from './containers/Layout/LoadingPage';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './containers/App/App'));

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  mixpanel.trackEvent('login');
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
  );
};

mixpanel.trackEvent('app_load');

const shouldLogAnalytics = process.env.REACT_APP_LOG_ANALYTICS === 'true';
getIsExternalUser().then((isExternalUser) => {
  if (shouldLogAnalytics) {
    mixpanel.setTracker(new LogTracker());
    analytics.setAnalytics(new LogAnalytics());
  } else if (isExternalUser) {
    mixpanel.setTracker(new MixpanelTracker());
    analytics.setAnalytics(new GoogleAnalytics());
  }
});

Bugsnag.start(bugsnagOptions);

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const app = (
  <ErrorBoundary>
    <GlobalStateProvider>
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        scope={config.scope}
        redirect_uri={window.location.origin}
        langague={i18n.language}
        audience={config.audience}
        onRedirectCallback={onRedirectCallback}
      >
        <ExperimentRootComponent>
          <Suspense fallback={<LoadingPage />}>
            <App />
          </Suspense>
        </ExperimentRootComponent>
      </Auth0Provider>
    </GlobalStateProvider>
  </ErrorBoundary>
);
const rootElement = document.getElementById('root');

if (module.hot) {
  module.hot.accept();
}

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

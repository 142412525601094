import React from 'react';
import PropTypes from 'prop-types';
import AnimationWrapper from '../AnimationWrapper/AnimationWrapper';
import loader from '../../../shared/animations/circle_loader_blue.json';

const LoadingIndicator = ({ style, size }) => (
  <AnimationWrapper
    options={{ animationData: loader }}
    style={style}
    width={size}
    height={size}
    className="load__icon-wrap"
  />
);

LoadingIndicator.defaultProps = {
  style: {
    textAlign: 'center',
  },
  size: 48,
};

LoadingIndicator.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  size: PropTypes.number,
};

export default LoadingIndicator;

import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import jwt from 'jsonwebtoken';
import UniversalCookies from 'universal-cookie';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
// eslint-disable-next-line import/no-cycle
import { clearStorage, setUserToken } from '../state';
import WaitableValue from '../utils/WaitableValue';
import { CustomerSuccessEnum, UserTypeEnum } from '../utils/enums';
import mixpanel from '../utils/mixpanel';
import { isDev } from '../utils/utils';

export const Auth0ScopeEnum = {
  ALL: 'all',
  CEP: 'cep',
  SCAN: 'scan',
};

const isDevMode = isDev();

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

const auth0ClientLocal = new WaitableValue();

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const decodeBusinessAuthorizations = (token) => {
  if (token) {
    const decoded = jwt.decode(token);

    if (decoded) {
      const { businesses, groups } = decoded['http://zeitgold-data/app_metadata'].authorization;
      if (businesses != null) {
        return businesses;
      }
      // Support old token usage
      if (groups != null) {
        return _.mapValues(_.keyBy(groups), () => ({ scopes: Auth0ScopeEnum.ALL }));
      }
    }
  }
  return {};
};

export const decodeDetailsBusinessList = (token) => {
  return _.keys(decodeBusinessAuthorizations(token));
};

export const decodeDetails = (token) => {
  if (token) {
    const decoded = jwt.decode(token);

    if (decoded)
      return {
        userId: decoded.sub,
        userEmail: decoded['http://zeitgold-data/name'],
        type: decoded['http://zeitgold-data/app_metadata'].type,
      };
  }
  return {};
};

export const logoutApp = async () => {
  const auth0Client = await auth0ClientLocal.get();
  await auth0Client.logout();
  return null;
};

const universalCookies = new UniversalCookies();
class DevAuth {
  static hasOverrideToken =
    process.env.STORYBOOK_OVERRIDE_TOKEN || universalCookies.get('has-override-token') === 'true';

  static isStagingProdDomain =
    window.location.hostname.split('.').length > 3 || universalCookies.get('has-override-token') === 'true';

  static parentDomain = window.location.host.split('.').slice(1).join('.');

  static loginOrigin = isDevMode
    ? window.location.origin
    : `${window.location.protocol}//login.${DevAuth.parentDomain}`;

  static iframeUrl = isDevMode
    ? `${DevAuth.loginOrigin}/prod-dev-login/load-token.html?origin=${window.location.origin}`
    : `${DevAuth.loginOrigin}/load-token.html?origin=${window.location.origin}`;

  // Gets override token from environment or login subdomain using iframe (see load-token.html file)
  static getOverrideToken = () => {
    if (!DevAuth.hasOverrideToken) {
      return null;
    }
    // From environment
    if (process.env.STORYBOOK_OVERRIDE_TOKEN) {
      return process.env.REACT_APP_STORYBOOK_TOKEN;
    }
    // From prod-dev-login
    if (DevAuth.savedOverrideToken) {
      return DevAuth.savedOverrideToken;
    }
    return new Promise((resolve) => {
      window.addEventListener('message', (e) => {
        if (e.origin === DevAuth.loginOrigin) {
          if (e.data.userToken) {
            DevAuth.savedOverrideToken = e.data.userToken;
            resolve(e.data.userToken);
          }
        }
      });
      if (!document.getElementById('override-token-iframe')) {
        const iframe = document.createElement('iframe');
        iframe.id = 'override-token-iframe';
        iframe.style = 'display: none;';
        iframe.src = DevAuth.iframeUrl;
        document.body.appendChild(iframe);
      }
    });
  };

  static redirectToLogin = () => {
    window.location.href = `${DevAuth.loginOrigin}/?redirect_url=${escape(window.location.href)}`;
  };

  static savedOverrideToken = null;
}

export const getToken = async () => {
  if (process.env.STORYBOOK_OVERRIDE_TOKEN) {
    return null;
  }
  const overrideToken = await DevAuth.getOverrideToken();
  let userToken;
  if (overrideToken) {
    userToken = overrideToken;
  } else {
    const auth0Client = await auth0ClientLocal.get();
    const token = await auth0Client.getTokenSilently();
    userToken = token.toString();
  }
  setUserToken(userToken);
  return userToken;
};

export const getAuth0BusinessList = async () => {
  const token = await getToken();
  if (token) {
    return decodeDetailsBusinessList(token);
  }
  return [];
};

export const getAuth0CustomerSuccess = async () => {
  const token = await getToken();
  if (token) {
    const decoded = jwt.decode(token);
    return decoded['http://zeitgold-data/app_metadata'].customerSuccess;
  }
  return null;
};

export const getAuth0UserType = async () => {
  const token = await getToken();
  if (token) {
    const { type } = decodeDetails(token);
    return type === undefined ? UserTypeEnum.user : type;
  }
  return null;
};

export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isCustomerSuccess, setIsCustomerSuccess] = useState();
  const [userType, setUserType] = useState();
  const [authorizationBusinesses, setAuthorizationBusinesses] = useState({});
  const [tanIds, setTanIds] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  async function setUserProperties(token) {
    const decoded = jwt.decode(token);
    const { type: decodedUserType, authorization, customerSuccess } = decoded['http://zeitgold-data/app_metadata'];
    const opsTypes = [CustomerSuccessEnum.read_only_agent, CustomerSuccessEnum.full_access_agent];
    const decodedIsCustomerSuccess = _.includes(opsTypes, customerSuccess);

    // if type is not set, it is a merchant user
    setUserType(decodedUserType || UserTypeEnum.user);
    setIsCustomerSuccess(decodedIsCustomerSuccess);
    setTanIds(authorization.tans);
    setAuthorizationBusinesses(decodeBusinessAuthorizations(token));
  }

  useEffect(() => {
    if (DevAuth.hasOverrideToken) {
      setIsAuthenticated(true);
      setLoading(false);
      if (!process.env.STORYBOOK_OVERRIDE_TOKEN) {
        getToken().then(setUserProperties);
      }
      return;
    }
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticatedHook = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticatedHook);

      // isAuthenticated is undefined under some circumstances, we use the hook to load the token
      if (isAuthenticatedHook) {
        const token = await auth0FromHook.getTokenSilently();
        await setUserProperties(token);
      }

      setLoading(false);

      auth0ClientLocal.set(auth0FromHook);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const logout = (options = {}) => {
    if (DevAuth.isStagingProdDomain) {
      return DevAuth.redirectToLogin();
    }
    return auth0Client.logout({
      returnTo: window.location.origin,
      ...options,
    });
  };

  const withEmailAddress = (options) => {
    const { email } = queryString.parse(document.location.search);
    if (email) {
      return {
        login_hint: email,
        ...options,
      };
    }
    return options;
  };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(withEmailAddress(params));
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    setIsAuthenticated(true);
  };

  const loginWithRedirect = (options, config) => {
    if (DevAuth.isStagingProdDomain) {
      return DevAuth.redirectToLogin();
    }
    const updatedOptions = withEmailAddress(options);
    return auth0Client.loginWithRedirect(updatedOptions, config);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const token = await auth0Client.getTokenSilently();
    await setUserProperties(token);
  };

  // TODO: Refactor from here to login/logout provider
  const [cookies, , removeCookie] = useCookies();
  const cleanCookies = () => {
    _.map(_.keys(cookies), (cookieName) => removeCookie(cookieName));
  };
  const logoutAndClean = async () => {
    clearStorage();
    // TODO: Why is this needed? This doesn't work right before logout...
    cleanCookies();
    await mixpanel.tryTrackEvent('logout', {});
    mixpanel.logout();
    logout();
  };
  // TODO: Refactor till here to login/logout provider

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        isCustomerSuccess,
        authorizationBusinesses,
        userType,
        tanIds,
        loading,
        popupOpen,
        logoutAndClean,
        loginWithPopup,
        loginWithRedirect,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

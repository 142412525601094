import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export const bugsnagOptions = {
  apiKey: '900608c41f18e997e9791c8fcc0d62d8',
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
  appVersion: process.env.TRAVIS_BUILD_NUMBER,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    const error = event.errors[0];
    // eslint-disable-next-line no-param-reassign
    event.groupingHash = `${error?.errorMessage} ${error?.errorClass}`;
  },
};

export const setBugsnagUserMeta = (id, email) => {
  Bugsnag.setUser(id, email);
};

export const setBugsnagUserBusinessMeta = (businessName, businessId) => {
  const user = Bugsnag.getUser();

  if (user) {
    const { id, email } = user;
    Bugsnag.setUser(id, email, `${email} - ${businessName}`);
    Bugsnag.addMetadata('user', 'businessId', businessId);
  }
};

export function logGraphQLToBugsnag(operationName, variables, requestType) {
  const data = {
    type: 'request',
    requestType,
    variables: JSON.stringify(variables),
    operationName,
  };
  Bugsnag.leaveBreadcrumb('GraphQL request executed', data);
}
